import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GoogleLogin } from '@react-oauth/google';

import { useLoginMutation } from '../graphql/server-graphql-schema';
import { setAuth } from '../store/authSlice';
import { RootState } from '../store/store';
import LogoutButton from './LogoutButton';

interface GoogleOAuthProps {
  redirectTo: string;
}
const GoogleOAuth: React.FC<GoogleOAuthProps> = ({ redirectTo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth.auth);
  const [loginMutation] = useLoginMutation();

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    try {
      const token = credentialResponse.credential;

      const { data, errors } = await loginMutation({
        fetchPolicy: 'network-only',
        variables: {
          token,
          source: 'google',
        },
      });

      if (data) {
        dispatch(setAuth(data.login));

        if (redirectTo) {
          void navigate(redirectTo);
        } else {
          void navigate('/settings');
        }
      }
    } catch (error) {
      console.error('Error calling GraphQL mutation:', error);
    }
  };

  const handleGoogleLoginError = () => {
    console.log('Login Failed');
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          {!auth.email ? (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <GoogleLogin
                size="large"
                theme="filled_blue"
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginError}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-4">
              <LogoutButton />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleOAuth;
