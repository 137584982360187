import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import sentenceReducer from './coreSlice';

// Persisted reducers
const persistedAuthReducer = persistReducer(
  {
    key: 'auth',
    storage,
  },
  authReducer,
);

const persistedSentenceReducer = persistReducer(
  {
    key: 'core',
    storage,
  },
  sentenceReducer,
);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    core: persistedSentenceReducer,
  },
  // By default, `devTools` is true in development and false in production
  // but you can explicitly set it to true to be sure.
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the `register` and `rehydrate` functions from redux-persist
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        // Ignore the paths where non-serializable data is stored
        ignoredPaths: ['register', 'rehydrate'],
      },
    }),
});

export const persistore = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
