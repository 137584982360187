import exifr from 'exifr';
import React, { useEffect, useRef, useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCamera, faHand } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MAX_IMAGE_SIZE_MB } from '../../../constants';
import {
    useExtractArtworkInfoFromImageMutation, useGenerateSignedUploadUrlLazyQuery
} from '../../../graphql/server-graphql-schema';
import { setError, setFromMinimal, updateOcrArtwork } from '../../../store/coreSlice';
import { RootState } from '../../../store/store';
import { cropImage, performOCR, resizeAndConvertToGrayscale } from '../../../utility/image-util';
import { uploadFileToGCS } from '../../../utility/uploadFile-util';

// type Artwork = ExtractArtworkInfoFromImageMutation['extractArtworkInfoFromImage']['artworks'][number]['m'];

type CompDragDropInfocardUploadProps = {};

const CompDragDropInfocardUpload: React.FC<CompDragDropInfocardUploadProps> = () => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [originalPreviewUrl, setOriginalPreviewUrl] = useState<string | null>(null);
  const [processedPreviewUrl, setProcessedPreviewUrl] = useState<string | null>(null);
  const resetCounter = useSelector((state: RootState) => state.core.resetCounter);
  const ocrArtwork = useSelector((state: RootState) => state.core.ocrArtwork);
  const fromMinimal = useSelector((state: RootState) => state.core.fromMinimal);

  const error = useSelector((state: RootState) => state.core.error);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();

  const [extractArtworkInfoFromImage] = useExtractArtworkInfoFromImageMutation();
  const [generateSignedUploadUrl] = useGenerateSignedUploadUrlLazyQuery({ fetchPolicy: 'network-only' });

  const originalImageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    resetState();
  }, [resetCounter]);

  const resetState = () => {
    setUploading(false);
    setOriginalPreviewUrl(null);
    setProcessedPreviewUrl(null);
    dispatch(setError(null));
  };

  const drawRectangles = (canvas: HTMLCanvasElement, img: HTMLImageElement, words: any[] | null, badWords: any[] | null) => {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

      if (words) {
        ctx.strokeStyle = 'red';
        ctx.fillStyle = 'rgba(255, 0, 0, 0.3)';
        ctx.lineWidth = 2;
        words.forEach((w: any) => {
          const b = w.bbox;
          ctx.strokeRect(b.x0, b.y0, b.x1 - b.x0, b.y1 - b.y0);
          ctx.fillRect(b.x0, b.y0, b.x1 - b.x0, b.y1 - b.y0); // Fill the rectangle
          // ctx.beginPath();
          // ctx.moveTo(w.baseline.x0, w.baseline.y0);
          // ctx.lineTo(w.baseline.x1, w.baseline.y1);
          // ctx.stroke();
        });
      }

      if (badWords) {
        ctx.strokeStyle = 'black';
        ctx.fillStyle = 'rgba(0, 0, 0, 0.3)'; // Set fill color to red with 30% opacity
        ctx.lineWidth = 2;
        badWords.forEach((w: any) => {
          const b = w.bbox;
          ctx.strokeRect(b.x0, b.y0, b.x1 - b.x0, b.y1 - b.y0);
          ctx.fillRect(b.x0, b.y0, b.x1 - b.x0, b.y1 - b.y0); // Fill the rectangle
          // ctx.beginPath();
          // ctx.moveTo(w.baseline.x0, w.baseline.y0);
          // ctx.lineTo(w.baseline.x1, w.baseline.y1);
          // ctx.stroke();
        });
      }
    }
  };

  const afterFileUpload = async (
    url: string,
    text: string,
    words: Tesseract.Block[] | null,
    badWords: Tesseract.Block[] | null,
    badText?: string,
  ) => {
    try {
      const extractResult = await extractArtworkInfoFromImage({
        variables: { input: { url, text, badText } },
      });

      if (
        extractResult.data?.extractArtworkInfoFromImage.success &&
        extractResult.data.extractArtworkInfoFromImage.artworks.length > 0
      ) {
        const artworkInfo = extractResult.data.extractArtworkInfoFromImage.artworks[0];
        dispatch(updateOcrArtwork(artworkInfo));
      } else {
        dispatch(setError(extractResult.data?.extractArtworkInfoFromImage.error || 'No text found'));
      }

      if (canvasRef.current && originalImageRef.current) {
        drawRectangles(canvasRef.current, originalImageRef.current, words, badWords);
      }
    } catch (err) {
      console.error(err);
      dispatch(setError('Failed to upload file or extract artwork info'));
    } finally {
      setUploading(false);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    dispatch(setError(null));

    if (acceptedFiles.length === 0) {
      dispatch(setError(t('errorMessages.noFilesSelected')));
      return;
    }

    const file = acceptedFiles[0]; // Ensure only one file is handled

    try {
      setUploading(true);
      setUploadProgress(0); // Reset progress

      let fileToUpload: File | Blob = file;

      if (file.type.startsWith('image/') && file.size > MAX_IMAGE_SIZE_MB) {
        dispatch(setError(t('errorMessages.fileTooBig')));
        setUploading(false);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        setOriginalPreviewUrl(reader.result as string); // Set original preview URL
      };
      reader.readAsDataURL(file);

      try {
        const exifData = await exifr.parse(file);
        const orientation = exifData.Orientation;
        console.log('EXIF data:', exifData);
        console.log('Image rotation (Orientation):', orientation);
      } catch (error) {
        console.error('Failed to extract EXIF data:', error);
      }

      const { text, badText, badWords, overallBbox, words } = await performOCR(fileToUpload);

      if (canvasRef.current && originalImageRef.current) {
        drawRectangles(canvasRef.current, originalImageRef.current, words, badWords);
      }

      if (!badText && text.length > 20) {
        fileToUpload = await cropImage(fileToUpload, overallBbox);
        fileToUpload = await resizeAndConvertToGrayscale(fileToUpload);
      }

      const processedReader = new FileReader();
      processedReader.onloadend = () => {
        setProcessedPreviewUrl(processedReader.result as string); // Set processed preview URL
      };
      processedReader.readAsDataURL(fileToUpload); // Convert processed file to data URL

      const originalFileName = await uploadFileToGCS(fileToUpload, generateSignedUploadUrl, (progress) => {
        setUploadProgress(progress); // Update progress
      });

      await afterFileUpload(originalFileName, text, words, badWords, badText);
    } catch (uploadError) {
      console.error(uploadError);
      dispatch(setError(t('errorMessages.failedToUploadFile')));
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1, // Ensure only one file can be uploaded
  });

  const renderDropZone = () => (
    <div style={styles.wrapper}>
      <img src={`${process.env.PUBLIC_URL}/images/hole-s.png`} style={styles.image} title={t('upload')} />
      <div {...getRootProps()} style={styles.dropzone}>
        <input {...getInputProps()} />
      </div>
    </div>
  );

  const renderButtons = () => (
    <div className="text-center my-4">
      <Button variant="primary" className="me-2" {...getRootProps()}>
        <FontAwesomeIcon icon={faCamera} className="me-2" /> {t('buttons.automatic')}
      </Button>
      <Button variant="outline-secondary" onClick={() => dispatch(setFromMinimal(!fromMinimal))}>
        <FontAwesomeIcon icon={faHand} className="me-2" /> {t('buttons.manual')}
      </Button>
      <input {...getInputProps()} style={styles.hiddenInput} />
    </div>
  );

  const renderProgressBar = () => (
    <div className="text-center">
      <FontAwesomeIcon icon={faSpinner} spin /> {t('uploading')}
      <ProgressBar now={uploadProgress} className="my-3" style={styles.progressBar} />
    </div>
  );

  const renderPreviewSection = () => (
    <div style={styles.previewSection}>
      <div style={styles.imageGrid}>
        {originalPreviewUrl && (
          <div style={styles.imageContainer}>
            <h6>{t('original')}</h6>
            <div style={styles.imageOverlayContainer}>
              <canvas ref={canvasRef} style={styles.overlayCanvas} />
              <img
                ref={originalImageRef}
                src={originalPreviewUrl}
                alt={t('original')}
                style={{ ...styles.previewImage, opacity: 0.5 }}
              />
            </div>
          </div>
        )}
        {processedPreviewUrl && (
          <div style={styles.imageContainer}>
            <h6>{t('processed')}</h6>
            <img src={processedPreviewUrl} alt={t('processed')} style={styles.previewImage} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      {!isMobile && !uploading && !ocrArtwork && (
        <>
          <h5 className="mb-5 mt-1 text-center">{t('scanArtworkLabelCard')}</h5>
          {renderDropZone()}
        </>
      )}
      {!uploading && !ocrArtwork && renderButtons()}
      <div className="mt-2">
        {uploading && renderProgressBar()}
        {error && <div style={styles.errorMessage}>{error}</div>}
        {(originalPreviewUrl || processedPreviewUrl) && renderPreviewSection()}
      </div>
    </div>
  );
};

export default CompDragDropInfocardUpload;

const styles: { [key: string]: React.CSSProperties } = {
  dropzone: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.2)',
    // border: '2px dashed #cccccc',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
  },
  icon: {
    fontSize: '24px',
    color: '#007bff',
    cursor: 'pointer',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  hiddenInput: {
    display: 'none',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
  },
  previewSection: {
    margin: '0 auto',
    marginTop: '20px',
    textAlign: 'center',
  },
  imageGrid: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  imageContainer: {
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '200px',
    maxHeight: '200px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  progressBar: {
    maxWidth: '500px',
    margin: '15px auto',
  },
  overlayCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10, // Ensure canvas is on top
    pointerEvents: 'none',
  },
  imageOverlayContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  fileInfo: {
    marginTop: '10px',
    textAlign: 'center',
    color: '#555',
  },
  exifInfo: {
    marginTop: '10px',
    textAlign: 'center',
    color: '#555',
  },
};
