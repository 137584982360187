import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateLocationPage from './CreateLocationPage';

type AddLocationButtonProps = {
  onLocationAdded: (newLocationId: string, name: string) => void; // Callback when a location is successfully added
};

export const AddLocationButton: React.FC<AddLocationButtonProps> = ({ onLocationAdded }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleLocationSuccess = (newLocationId: string, name: string) => {
    onLocationAdded(newLocationId, name); // Notify parent about the new location
    handleCloseModal();
  };

  return (
    <div>
      <Button variant="outline-primary" onClick={handleOpenModal}>
        <FontAwesomeIcon icon={faPlus} title={t('add')} />
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('createNewLocation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateLocationPage onClose={handleCloseModal} onSuccess={handleLocationSuccess} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
