import React from 'react';
import { Alert, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useGetAllLocationsQuery } from '../../graphql/server-graphql-schema';

const CompHomeLocationList: React.FC = () => {
  const { data, loading, error } = useGetAllLocationsQuery({
    variables: { page: 1, limit: 10 },
  });

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  const locations = data?.getAllLocations?.locations || [];

  return (
    <Container>
      <Row>
        {locations.map((location) => (
          <Col key={location.id} sm={12} md={6} lg={4} className="mb-4">
            <Link to={`/artworks/location/${location.id}`} style={{ textDecoration: 'none' }}>
              <Card>
                <img src={`${process.env.PUBLIC_URL}/images/museum3.png`} title={location.name} style={styles.image} />
                <Card.Body>
                  <Card.Title>
                    <strong>{location.name}</strong>
                  </Card.Title>
                  <Card.Text>
                    <span>{location.city}</span>
                    <span className="ms-3">{location.country}</span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CompHomeLocationList;

const styles: { [key: string]: React.CSSProperties } = {
  image: {
    maxHeight: '140px',
    width: '100%',
    objectFit: 'contain',
  },
};
