import { CSSProperties, FC } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CompHero } from './CompHero';
import CompHomeLocationList from './CompHomeLocationList';
import { CompSampleArtworks } from './CompSampleArtworks';

const HomePage: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (path: string) => {
    if (location.pathname !== path) {
      void navigate(path);
    }
  };

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <Helmet>
        <title>{t('homePage.title')}</title>
        <meta
          name="description"
          content="Explore museums like never before. With ArtHista, every visit becomes an immersive journey."
        />
        <meta name="keywords" content="museum, art, guide, artworks, artist stories, museum guide, ArtHista" />
        <meta name="author" content="ArtHista" />
      </Helmet>

      <div className="container-fluid p-0 my-5">
        <CompHero />
      </div>

      <div className={isMobile ? 'my-2' : 'my-5'}>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center my-5">{t('homePage.sampleArtworks')}</h2>
            <CompSampleArtworks />
          </div>
        </div>
      </div>

      <div className={isMobile ? 'my-2' : 'my-5'}>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center my-5">{t('homePage.museumList')}</h2>
            <CompHomeLocationList />
          </div>
        </div>
      </div>

      <div className={(isMobile ? 'my-2' : 'my-5') + ' d-flex flex-column align-items-center'} style={{ paddingBottom: '200px' }}>
        <h2 className="text-center my-5">{t('homePage.search')}</h2>
        <Button
          variant="primary"
          size="lg"
          onClick={() => handleLinkClick('/search')}
          className="d-flex align-items-center justify-content-center"
        >
          <FontAwesomeIcon size="lg" icon={faSearch} className="me-2" />
          {t('Search')}
        </Button>
      </div>
    </Container>
  );
};

const styles: { [key: string]: CSSProperties } = {};

export default HomePage;
