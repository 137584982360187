import pica from 'pica';
import { createWorker, PSM } from 'tesseract.js';

import { BBOX_PADDING, MAX_INFOCARD_IMAGE_DIMENSION } from '../constants';

import type { Block, Worker } from 'tesseract.js';

// Need to add loading lang files locally so it can work offline
// https://github.com/naptha/tesseract.js/blob/master/docs/local-installation.md

function evaluateOCRQuality(ocrText: string): string {
  // Count alphanumeric characters
  const alphanumericCount = (ocrText.match(/[a-zA-Z0-9]/g) || []).length;
  // Count non-alphanumeric characters
  const nonAlphanumericCount = (ocrText.match(/[^a-zA-Z0-9\s]/g) || []).length;

  // Calculate total characters
  const totalCharacters = alphanumericCount + nonAlphanumericCount;

  // Calculate the ratio of non-alphanumeric characters
  const nonAlphanumericRatio = totalCharacters > 0 ? nonAlphanumericCount / totalCharacters : 0;

  // Calculate average word length
  const words = ocrText.split(/\s+/).filter((word) => word.length > 0);
  const averageWordLength = words.length > 0 ? words.reduce((sum, word) => sum + word.length, 0) / words.length : 0;

  // Define thresholds
  const maxNonAlphanumericRatio = 0.4; // Adjust based on typical OCR quality
  const minAverageWordLength = 3; // Average word length for structured text
  console.log(
    (nonAlphanumericRatio > maxNonAlphanumericRatio).toString() + '  nonAlphanumericRatio:',
    nonAlphanumericRatio,
    '>',
    maxNonAlphanumericRatio,
  );
  console.log(
    (averageWordLength < minAverageWordLength).toString() + '  averageWordLength:',
    averageWordLength,
    '<',
    minAverageWordLength,
  );
  // Determine quality based on thresholds
  if (nonAlphanumericRatio > maxNonAlphanumericRatio || averageWordLength < minAverageWordLength) {
    console.log('Bad OCR quality detected. Retry.');

    console.log(ocrText);

    return 'bad';
  } else {
    return 'ok';
  }
}

export const performOCR = async (image: Blob) => {
  const worker: Worker = await createWorker('eng');

  try {
    await worker.setParameters({
      tessedit_pageseg_mode: PSM.SPARSE_TEXT,
    });
    let result = await worker.recognize(
      image,
      {},
      {
        text: true,
        blocks: true,
      },
    );
    let text = result.data.text;
    let badText = undefined;
    let badWords: Block[] | null = [];

    // Evaluate OCR quality
    if (evaluateOCRQuality(text) === 'bad') {
      badText = text;
      badWords = result.data.blocks;

      // Re-run recognition with auto rotation
      await worker.setParameters({
        rotate_auto: true,
      });
      result = await worker.recognize(image);
      text = result.data.text;
    }

    console.log('OCR result:', text);

    await worker.terminate();

    // Extract text and bounding boxes
    const boxes =
      result.data.blocks?.map((word) => ({
        text: word.text,
        bbox: {
          x0: word.bbox.x0,
          y0: word.bbox.y0,
          x1: word.bbox.x1,
          y1: word.bbox.y1,
        },
      })) || [];

    // Calculate overall bounding box
    const overallBbox = boxes.reduce(
      (acc, box) => {
        acc.x0 = Math.min(acc.x0, box.bbox.x0);
        acc.y0 = Math.min(acc.y0, box.bbox.y0);
        acc.x1 = Math.max(acc.x1, box.bbox.x1);
        acc.y1 = Math.max(acc.y1, box.bbox.y1);
        return acc;
      },
      { x0: Infinity, y0: Infinity, x1: -Infinity, y1: -Infinity },
    );

    // Expand the bounding box by the defined padding
    overallBbox.x0 = Math.max(0, overallBbox.x0 - BBOX_PADDING);
    overallBbox.y0 = Math.max(0, overallBbox.y0 - BBOX_PADDING);
    overallBbox.x1 += BBOX_PADDING;
    overallBbox.y1 += BBOX_PADDING;

    return { text, boxes, overallBbox, words: result.data.blocks || [], badText, badWords };
  } catch (error) {
    console.error('Error performing OCR:', error);
    await worker.terminate();
    throw error;
  }
};

// Function to crop the image based on the overall bounding box
export const cropImage = async (image: Blob, bbox: { x0: number; y0: number; x1: number; y1: number }): Promise<Blob> => {
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      img.src = event.target?.result as string;

      img.onload = () => {
        const width = bbox.x1 - bbox.x0;
        const height = bbox.y1 - bbox.y0;

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.drawImage(img, bbox.x0, bbox.y0, width, height, 0, 0, width, height);
        } else {
          reject(new Error('Failed to get 2D context'));
        }

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Canvas is empty'));
          }
        }, image.type);
      };

      img.onerror = (error) => reject(error);
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(image);
  });
};

const picaInstance = pica();

export const resizeAndConvertToGrayscale = async (image: Blob): Promise<Blob> => {
  const img = document.createElement('img');

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        img.src = event.target.result as string;

        img.onload = async () => {
          // Calculate the new dimensions while preserving the aspect ratio
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_INFOCARD_IMAGE_DIMENSION) {
              height = Math.round((height * MAX_INFOCARD_IMAGE_DIMENSION) / width);
              width = MAX_INFOCARD_IMAGE_DIMENSION;
            }
          } else {
            if (height > MAX_INFOCARD_IMAGE_DIMENSION) {
              width = Math.round((width * MAX_INFOCARD_IMAGE_DIMENSION) / height);
              height = MAX_INFOCARD_IMAGE_DIMENSION;
            }
          }

          // Resize the image using pica
          const offscreenCanvas = document.createElement('canvas');
          offscreenCanvas.width = img.width;
          offscreenCanvas.height = img.height;

          const offscreenCtx = offscreenCanvas.getContext('2d');
          if (offscreenCtx) {
            offscreenCtx.drawImage(img, 0, 0);
          }

          const resizedCanvas = document.createElement('canvas');
          resizedCanvas.width = width;
          resizedCanvas.height = height;

          await picaInstance.resize(offscreenCanvas, resizedCanvas);

          // Convert to grayscale
          const resizedCtx = resizedCanvas.getContext('2d');
          if (resizedCtx) {
            const imageData = resizedCtx.getImageData(0, 0, resizedCanvas.width, resizedCanvas.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
              const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
              data[i] = avg; // Red
              data[i + 1] = avg; // Green
              data[i + 2] = avg; // Blue
            }

            resizedCtx.putImageData(imageData, 0, 0);

            resizedCanvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Canvas is empty'));
              }
            }, 'image/png');
          } else {
            reject(new Error('Failed to get 2D context'));
          }
        };

        img.onerror = (error) => reject(error);
      } else {
        reject(new Error('Failed to read image'));
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(image);
  });
};

// // New function to convert image data to HSI
// function convertToHSI(data: Uint8ClampedArray) {
//   // Simple placeholder for an HSI-like transform
//   for (let i = 0; i < data.length; i += 4) {
//     const r = data[i];
//     const g = data[i + 1];
//     const b = data[i + 2];

//     // ...compute hue, saturation, intensity...
//     const intensity = (r + g + b) / 3;

//     // Assign the average (intensity) back as a demo
//     data[i] = intensity;
//     data[i + 1] = intensity;
//     data[i + 2] = intensity;
//   }
// }

// // Example function applying HSI before OCR
// export async function performOCRWithHSI(image: Blob) {
//   const worker: Worker = await createWorker('eng');

//   try {
//     let resultCanvas = document.createElement('canvas');
//     // ...existing code to draw image to canvas...
//     const ctx = resultCanvas.getContext('2d');
//     if (ctx) {
//       let imageData = ctx.getImageData(0, 0, resultCanvas.width, resultCanvas.height);
//       convertToHSI(imageData.data);
//       ctx.putImageData(imageData, 0, 0);
//     }

//     // Convert canvas back to Blob to pass into Tesseract
//     const processedImage = await new Promise<Blob>((resolve, reject) => {
//       resultCanvas.toBlob((blob) => {
//         blob ? resolve(blob) : reject(new Error('Canvas is empty'));
//       }, 'image/png');
//     });

//     // ...existing code integrated with OCR...
//     let result: Page = await worker.recognize(processedImage);
//     // ...rest of your OCR logic with evaluateOCRQuality, etc...
//     await worker.terminate();
//     return { text: result.text };
//   } catch (error) {
//     console.error('Error performing OCR with HSI:', error);
//     await worker.terminate();
//     throw error;
//   }
// }
