import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { clearAuth } from '../store/authSlice';
import { RootState, store } from '../store/store';

const API_URL = process.env.REACT_APP_API_URL || '';

const httpLink = new HttpLink({ uri: API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  const state: RootState = store.getState();

  operation.setContext({
    headers: {
      Authorization: state.auth.auth.jwt,
    },
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message.indexOf('TOKEN_EXPIRED') > -1) {
        store.dispatch(clearAuth());
      }
    }
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(),
});
