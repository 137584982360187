import { OcrLocalizedText } from '../graphql/server-graphql-schema';

export const renderDescription = (description: string | null | undefined) => {
  if (!description) return null;

  return description.split('\n\n').map((paragraph, index) => (
    <p key={index} style={{ marginBottom: '1rem' }}>
      {paragraph}
    </p>
  ));
};

export const formHasData = (ocrArtwork: any) => {
  const hasText = (texts?: OcrLocalizedText[] | null) => {
    if (!texts) return false;
    return texts.some((item) => Boolean(item.text?.trim()));
  };

  return hasText(ocrArtwork?.artistNames) || hasText(ocrArtwork?.artworkNames);
};
