import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { faEdit, faLocationDot, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CompImageThumbs from '../../components/CompImageThumbs';
import CompDownloadAndPlayVoice from '../../components/DownloadAndPlayVoice';
import { Artwork } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';

interface ArtworkProps {
  data: Artwork;
  auth: { role: string; id: string };
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  detailPageRoute: string;
}

const ArtworkRenderer: React.FC<ArtworkProps> = ({ data: artwork, auth, onEdit, onDelete, detailPageRoute }) => {
  const { t } = useTranslation();
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Link to={detailPageRoute} style={styles.title}>
          <FontAwesomeIcon icon={faImage} className="me-2" style={{ color: '#999', fontSize: '115%' }} />
          {getDefaultText(artwork, 'name') || t('untitled')}
        </Link>
        <div>
          {artwork.makerName || t('unknown')}, {artwork.dateStarted || t('unknown')}
        </div>
      </div>
      <div style={styles.infoContainer}>
        <div style={styles.actionButtons}>
          {getDefaultText(artwork, 'description') && <CompDownloadAndPlayVoice item={artwork} lang={lang} />}

          {auth.role === 'ADMIN' && (
            <>
              <Button variant="outline-primary" size="sm" onClick={() => onEdit(artwork.id)} title={t('editArtwork')}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button variant="outline-danger" size="sm" onClick={() => onDelete(artwork.id)} title={t('deleteArtwork')}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          )}
        </div>

        <div style={styles.body}>
          <div style={styles.description}>
            {artwork ? <ReactMarkdown>{getDefaultText(artwork, 'description')}</ReactMarkdown> : <p>{t('unknown')}</p>}
          </div>

          <div style={styles.location}>
            {t('createdIn')}: <FontAwesomeIcon icon={faLocationDot} style={styles.icon} /> {artwork.locationName || t('unknown')}
          </div>
          {/* {artwork.meta?.medium && (
            <div style={styles.additionalInfo}>
              <span>{artwork.meta.medium}</span>
            </div>
          )}
          {artwork.meta?.relations && (
            <div style={styles.relations}>
              {artwork.meta.relations.people && (
                <p>
                  <strong>People:</strong> {artwork.meta.relations.people.join(', ')}
                </p>
              )}
              {artwork.meta.relations.location && (
                <p>
                  <strong>Location:</strong> {artwork.meta.relations.location}
                </p>
              )}
              {artwork.meta.relations.theme && (
                <p>
                  <strong>Theme:</strong> {artwork.meta.relations.theme}
                </p>
              )}
              {artwork.meta.relations.eventDepicted && (
                <p>
                  <strong>Event:</strong> {artwork.meta.relations.eventDepicted}
                </p>
              )}
              {artwork.meta.relations.iconography && (
                <p>
                  <strong>Iconography:</strong> {artwork.meta.relations.iconography}
                </p>
              )}
              {artwork.meta.relations.controversy && (
                <p>
                  <strong>Controversy:</strong> {artwork.meta.relations.controversy}
                </p>
              )}
              {artwork.meta.relations.socioPolitical && (
                <p>
                  <strong>Socio-Political:</strong> {artwork.meta.relations.socioPolitical}
                </p>
              )}
              {artwork.meta.relations.historical && (
                <p>
                  <strong>Historical:</strong> {artwork.meta.relations.historical}
                </p>
              )}
              {artwork.meta.relations.culturalSensitive && (
                <p>
                  <strong>Cultural Sensitivity:</strong> {artwork.meta.relations.culturalSensitive}
                </p>
              )}
              {artwork.meta.relations.politicalStatement && (
                <p>
                  <strong>Political Statement:</strong> {artwork.meta.relations.politicalStatement}
                </p>
              )}
              {artwork.meta.relations.religiousTheme && (
                <p>
                  <strong>Religious Theme:</strong> {artwork.meta.relations.religiousTheme}
                </p>
              )}
              {artwork.meta.relations.symbolism && (
                <p>
                  <strong>Symbolism:</strong> {artwork.meta.relations.symbolism}
                </p>
              )}
            </div>
          )} */}
        </div>
      </div>
      {artwork.media && <CompImageThumbs artwork={artwork} showDate={false} />}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px',
    flexWrap: 'wrap',
  },
  imageContainer: {
    flex: '0 0 150px',
    marginLeft: '16px',
  },
  infoContainer: {
    flex: '1',
    marginRight: '16px',
  },
  icon: {
    marginRight: '8px',
  },
  body: {
    marginTop: '8px',
  },
  description: {
    textAlign: 'justify',
    marginTop: '8px',
  },
  location: {
    marginTop: '8px',
    fontStyle: 'italic',
  },
  additionalInfo: {
    marginTop: '8px',
  },
  relations: {
    marginTop: '16px',
  },
  header: {
    width: '100%',
    marginBottom: '16px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  buttonContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButtons: {
    display: 'flex',
    gap: '20px',
    marginBottom: '10px',
  },
  imageThumbsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

export default ArtworkRenderer;
