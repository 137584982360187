import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import FooterComponent from './components/FooterComponent';
import NavBarComponent from './components/NavBarComponent';
import { apolloClient } from './graphql/ApolloClientSetup';
import { ToastProvider } from './hooks/ToastNotificationProvider';
import PublicRoutes from './PublicRoutes';
import { persistore, store } from './store/store';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ToastProvider>
          <PersistGate loading={null} persistor={persistore}>
            <ApolloProvider client={apolloClient}>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <div className="d-flex flex-column min-vh-100">
                  <BrowserRouter>
                    <NavBarComponent />
                    <PublicRoutes />
                    <FooterComponent />
                  </BrowserRouter>
                </div>
              </GoogleOAuthProvider>
            </ApolloProvider>
          </PersistGate>
        </ToastProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
