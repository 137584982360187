import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Form, ListGroup, Row, Spinner } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'react-responsive'; // Add this import
import { useNavigate } from 'react-router-dom';

import { useGetAllUsersQuery } from '../../graphql/server-graphql-schema';

const UserListPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Add this line
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading, error, refetch } = useGetAllUsersQuery({
    variables: {
      page: currentPage,
      limit: itemsPerPage,
    },
    fetchPolicy: 'network-only',
  });

  const fuse = useMemo(() => {
    if (!data?.getAllUsers?.users) return null;
    return new Fuse(data.getAllUsers.users, {
      keys: ['name', 'email', 'role'],
      threshold: 0.3,
    });
  }, [data?.getAllUsers?.users]);

  const filteredUsers = useMemo(() => {
    if (!searchQuery || !fuse) return data?.getAllUsers?.users || [];
    return fuse.search(searchQuery).map((result) => result.item);
  }, [fuse, searchQuery, data?.getAllUsers?.users]);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };

  const totalPages = Math.max(Math.ceil((data?.getAllUsers?.total || 0) / itemsPerPage), 1);

  const renderPagination = () => (
    <Row className="mt-4">
      <Col className="text-center">
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
          forcePage={currentPage - 1} // Ensure the current page is highlighted
        />
      </Col>
    </Row>
  );

  if (loading) {
    return (
      <Container className={isMobile ? 'mt-2' : 'mt-5'}>
        {' '}
        {/* Update className */}
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={isMobile ? 'mt-2' : 'mt-5'}>
        {' '}
        {/* Update className */}
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      {' '}
      {/* Update className */}
      <Row className="mb-4">
        <Col>
          <h2>Users:</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Control
            type="text"
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Col>
      </Row>
      {renderPagination()}
      <Row className="mb-2 fw-bold">
        <Col sm={5}></Col>
        <Col sm={1}>AI Credit</Col>
        <Col sm={1}>Artwork</Col>
        <Col sm={1}>Media</Col>
        <Col sm={1}>Role</Col>
        <Col sm={3}></Col>
      </Row>
      <ListGroup>
        {filteredUsers.map((user) => (
          <ListGroup.Item key={user.id}>
            <Row>
              <Col sm={5}>
                <strong>{user.name}</strong>
                <div>{user.email}</div>
              </Col>
              <Col sm={1}>
                <div>{user.quota?.aiCredits}</div>
              </Col>
              <Col sm={1}>
                <div>{user.quota?.artwork}</div>
              </Col>
              <Col sm={1}>
                <div>{user.quota?.media}</div>
              </Col>
              <Col sm={1}>
                <div>{user.role}</div>
              </Col>
              <Col sm={3} className="text-end">
                <Button variant="outline-primary" size="sm" onClick={() => navigate(`/users/${user.id}`)}>
                  View Details
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {renderPagination()}
    </Container>
  );
};

export default UserListPage;
