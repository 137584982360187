import React, { useState } from 'react';

import { CONTENT_BASE_URL } from '../constants';
import { GetAllArtworksQuery } from '../graphql/server-graphql-schema';
import { getDefaultText } from '../utility/text-util';

type Artwork = GetAllArtworksQuery['getAllArtworks']['artworks'][number];

interface ImageThumbsProps {
  artwork: Artwork;
  showDate: boolean;
}

const CompImageThumbs: React.FC<ImageThumbsProps> = ({ artwork, showDate }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const nameText = getDefaultText(artwork, 'name') || 'Untitled';

  if (!artwork || !artwork?.media) return null;

  const media = [...artwork?.media].sort((a, b) => a.order - b.order);
  const mainImage = hoveredIndex !== null ? media[hoveredIndex] : media[0];

  return (
    <>
      {artwork?.media.length > 0 && (
        <div style={styles.container}>
          <div style={styles.mainImageContainer}>
            <img
              src={CONTENT_BASE_URL + mainImage?.urls?.thumb}
              alt={`image: ${nameText}`}
              className="artwork-image-preview"
              style={styles.mainImage}
            />
            {showDate && mainImage?.createdAt && (
              <p style={styles.dateText}>{new Date(parseInt(mainImage.createdAt, 10)).toLocaleDateString()}</p>
            )}
          </div>
          {media.length > 1 && (
            <div style={styles.gridContainer}>
              {media
                .slice(1) // Exclude the first image from the grid
                .map(
                  (mediaItem, index) =>
                    mediaItem?.urls?.thumb && (
                      <div
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index + 1)} // Adjust index for hovered image
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={styles.gridItem}
                      >
                        <img
                          src={CONTENT_BASE_URL + mediaItem.urls.thumb}
                          alt={`image: ${nameText}`}
                          className="artwork-image-preview"
                          style={styles.gridImage}
                        />
                      </div>
                    ),
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  mainImageContainer: {
    marginRight: '10px',
    textAlign: 'center',
  },
  mainImage: {
    width: '150px',
    height: '150px',
    borderRadius: '5px',
    objectFit: 'contain',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 50px)',
    gridGap: '5px',
  },
  gridItem: {
    textAlign: 'center',
  },
  gridImage: {
    width: '50px',
    height: '50px',
    borderRadius: '5px',
    objectFit: 'cover',
  },
  dateText: {
    textAlign: 'center',
    marginTop: '5px',
  },
};

export default CompImageThumbs;
