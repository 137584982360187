import Fuse from 'fuse.js';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';

import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faImage, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';

type CompSearchBarProps = {
  onSearch: (text: string) => void;
  loading: boolean;
};

type OptionType = {
  value: string;
  type: 'artwork' | 'maker';
  item: any;
};

const CompSearchBar: React.FC<CompSearchBarProps> = ({ onSearch, loading }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');
  const artworks = useSelector((state: RootState) => state.core.artworks);
  const makers = useSelector((state: RootState) => state.core.makers);
  const [options, setOptions] = useState<OptionType[]>([]);
  const navigate = useNavigate();

  const MIN_SEARCH_LENGTH = 2;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (searchValue.length >= MIN_SEARCH_LENGTH) {
      const fuseArtworks = new Fuse(artworks, {
        keys: ['text.en.name.default.text', 'maker.text.en.name.default.text', 'makerName', 'dateStarted'],
        threshold: 0.3,
      });
      const fuseMakers = new Fuse(makers, {
        keys: ['text.en.name.default.text', 'dateOfBirth', 'dateOfDeath', 'birthPlace'],
        threshold: 0.3,
      });

      const filteredArtworks = fuseArtworks.search(searchValue, { limit: 10 }).map((result) => result.item);
      const filteredMakers = fuseMakers.search(searchValue, { limit: 10 }).map((result) => result.item);

      setOptions([
        ...filteredArtworks.map((artwork) => ({
          value: artwork.id,
          type: 'artwork' as OptionType['type'],
          item: artwork,
        })),
        ...filteredMakers.map((maker) => ({
          value: maker.id,
          type: 'maker' as OptionType['type'],
          item: maker,
        })),
      ]);
    } else {
      setOptions([]);
    }
  }, [searchValue]);

  const formatOptionLabel = ({ type, value, item }: OptionType) => {
    if (type === 'artwork') {
      return (
        <div key={value} className="d-flex" style={styles.searchItems}>
          <div>
            <FontAwesomeIcon icon={faImage} className="me-2" style={{ fontSize: '115%' }} />

            {getDefaultText(item, 'name')}

            {(getDefaultText(item.maker, 'name') || item.makerName) && (
              <span className="ms-2">- {getDefaultText(item.maker, 'name') || item.makerName}</span>
            )}
            <span className="ms-2">{item.dateStarted}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div key={value} className="d-flex" style={styles.searchItems}>
          <div>
            <FontAwesomeIcon icon={faUser} className="me-2" />
            {getDefaultText(item, 'name')} ({item.dateOfBirth}-{item.dateOfDeath}) {item.birthPlace}
          </div>
        </div>
      );
    }
  };

  const handleInputChange = (newValue: string, { action }: { action: string }) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setSearchValue(newValue);
    }
  };

  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      setSearchValue(getDefaultText(selectedOption.item, 'name') || '');
      if (selectedOption.type === 'artwork') {
        void navigate(`/artworks/${selectedOption.value}`);
      } else if (selectedOption.type === 'maker') {
        void navigate(`/makers/${selectedOption.value}`);
      }
    }
  };

  const handleSearch = (text: string) => {
    if (!text) return;
    onSearch(text);
  };

  // const handleClear = () => {
  //   setSearchValue('');
  // };

  const customStyles: StylesConfig<OptionType, false> = {
    menuList: (provided) => ({
      ...provided,
      maxHeight: isMobile ? '400px' : '800px', // Maximum height of the dropdown menu
    }),
  };

  // const CustomClear = ({ onClear, style }: { onClear: () => void; style?: CSSProperties }) => {
  //   return (
  //     <button style={{ ...styles.customClearButton, ...style }} onClick={onClear}>
  //       <FontAwesomeIcon icon={faTimes} />
  //     </button>
  //   );
  // };

  return (
    <div className="d-flex flex-column w-100" style={styles.container}>
      <div className="d-flex w-100 position-relative" style={styles.positionRelative}>
        <Select
          options={options}
          onInputChange={handleInputChange}
          onChange={handleChange}
          isLoading={loading}
          placeholder={t('typeToSearch')}
          className="me-2 w-100"
          formatOptionLabel={formatOptionLabel}
          inputValue={searchValue} // Ensure this line is present
          styles={customStyles} // Apply custom styles
          getOptionLabel={(option) => getDefaultText(option.item, 'name') || ''}
          getOptionValue={(option) => option.value}
        />
        <Button
          variant="primary"
          className={isMobile ? 'ms-3' : ''}
          onClick={() => handleSearch(searchValue)}
          disabled={loading || !searchValue}
        >
          {loading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faSearch} />}
        </Button>
        {/* {searchValue && <CustomClear onClear={handleClear} style={{ right: isMobile ? '120px' : '100px' }} />} */}
      </div>
    </div>
  );
};

export default CompSearchBar;

const styles: { [key: string]: CSSProperties } = {
  container: {},
  searchItems: {
    // Add your styles here
  },
  artworkDetails: {
    // Add your styles here
  },
  customClearButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#6c757d',
  },
  positionRelative: {
    position: 'relative',
  },
};
