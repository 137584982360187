import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { useGetAllLocationsQuery } from '../graphql/server-graphql-schema';
import { setCurrentLocation } from '../store/coreSlice';
import { RootState } from '../store/store';

const CompLocationSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading, error } = useGetAllLocationsQuery({ variables: { page: 1, limit: 100 } });
  const [locations, setLocations] = useState<{ value: string; label: string }[]>([]);
  const currentLocation = useSelector((state: RootState) => state.core.currentLocation); // Get current location from the store
  const [selectedLocation, setSelectedLocation] = useState<{ value: string; label: string } | null>(null);

  useEffect(() => {
    if (data?.getAllLocations?.locations) {
      const locationOptions = data.getAllLocations.locations.map((location) => ({
        value: location.id,
        label: location.name,
      }));
      setLocations(locationOptions);

      // Set the selected location based on the current location from the store
      if (currentLocation) {
        const selected = locationOptions.find((location) => location.value === currentLocation.id);
        setSelectedLocation(selected || null);
      }
    }
  }, [data, currentLocation]);

  const handleLocationChange = (selected: { value: string; label: string } | null) => {
    setSelectedLocation(selected);
    if (selected) {
      dispatch(setCurrentLocation({ id: selected.value, name: selected.label }));
    } else {
      dispatch(setCurrentLocation(null));
    }
  };

  if (loading) return <div>{t('loading')}</div>;
  if (error) return <div>{t('errorLoadingLocations')}</div>;

  return (
    <Select
      options={locations}
      value={selectedLocation}
      placeholder={t('selectLocation')}
      onChange={handleLocationChange}
      isClearable
    />
  );
};

export default CompLocationSelector;
