import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GoogleOAuth from '../../components/GoogleOAuth';
import {
    Artwork, GetArtworksOrMakersQuery, Maker, OcrArtwork, useFillInfoArtworkMutation, useGetArtworksOrMakersLazyQuery
} from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import ArtworkRenderer from '../artwork/ArtworkRenderer';
import MakerRenderer from '../maker/MakerRenderer';
import CompLoadArtworkAndMakers from './CompLoadArtworkAndMakers';
import CompSearchBar from './CompSearchBar';

const SearchPage: React.FC = () => {
  const { t } = useTranslation();

  const [results, setResults] = useState<GetArtworksOrMakersQuery['getArtworksOrMakers'] | null>(null);
  const [fetchResults, { loading, error }] = useGetArtworksOrMakersLazyQuery({
    fetchPolicy: 'network-only',
  });

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth.auth);

  const handleSearch = async (artworkInfo: OcrArtwork | string) => {
    if (!artworkInfo) return;

    setResults(null);
    try {
      const { data } = await fetchResults({
        variables: {
          input: {
            searchString: typeof artworkInfo === 'string' ? artworkInfo : undefined,
            searchJson: typeof artworkInfo !== 'string' ? artworkInfo : undefined,
            level: 1,
          },
        },
      });

      if (data?.getArtworksOrMakers) {
        setResults(data.getArtworksOrMakers);
      }
    } catch (err) {
      console.error('Error fetching results:', err);
      setResults(null);
    }
  };

  return (
    <Container
      className={isMobile ? 'mt-2' : 'mt-5'} // Update className
      style={{
        minHeight: results ? 'auto' : '70vh', // Ensure full height before search
        paddingBottom: results ? '300px' : '500px', // Adjust bottom padding dynamically
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 100,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CompLoadArtworkAndMakers />
      </div>

      {!auth.email ? (
        <>
          <h2 className="my-4 text-center">{t('loginPrompt')}</h2>
          <p className="mt-5 text-center mx-auto" style={{ maxWidth: '70%' }}>
            {t('loginDescription')}
            <span className="ms-2">
              <strong>{t('freeMention')}</strong>
            </span>
          </p>
          <div className="mt-5 text-center">
            <GoogleOAuth redirectTo={'/search'} />
          </div>
        </>
      ) : (
        <Row className="justify-content-center">
          <Col sm={12} md={9}>
            {!results && <h1 className="text-center mb-4">{t('searchPage.searchPrompt')}</h1>}

            {isMobile ? (
              <div className="d-flex flex-column align-items-center mb-3">
                <div className="w-100 mb-3">
                  <CompSearchBar loading={loading} onSearch={handleSearch} />
                </div>
                <Button
                  style={{ marginTop: 20, width: 150 }}
                  variant="primary"
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => navigate('/search-image')} // Fix the navigate path
                  disabled={!auth.email} // Disable button if user is logged out
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" />
                  Image Search
                </Button>
              </div>
            ) : (
              <div className="d-flex flex-row align-items-center mb-3">
                <CompSearchBar loading={loading} onSearch={handleSearch} />
                <Button
                  style={{ width: 200 }}
                  variant="primary"
                  className="ms-3 d-flex align-items-center justify-content-center"
                  onClick={() => navigate('/search-image')}
                  disabled={!auth.email} // Disable button if user is logged out
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" />
                  Image Search
                </Button>
              </div>
            )}

            {error && <div className="text-danger text-center">Error: {error.message}</div>}
          </Col>
        </Row>
      )}
      {results && (
        <Row className="justify-content-center mt-4">
          <Col md={10}>
            {results.artworks?.length > 0 && (
              <div className="mb-4">
                {results.artworks.map((artwork: Artwork, index: number) => (
                  <ArtworkRenderer
                    key={index + 'a'}
                    data={artwork}
                    auth={{ role: 'USER', id: '' }}
                    onEdit={() => {}}
                    onDelete={() => {}}
                    detailPageRoute={`/artworks/${artwork.id}`}
                  />
                ))}
              </div>
            )}
            {results.makers?.length > 0 && (
              <div className="mb-4">
                {results.makers.map((maker: Maker, index: number) => (
                  <MakerRenderer key={index + 'm'} data={maker} />
                ))}
              </div>
            )}
            {results.artworks?.length === 0 && results.makers?.length === 0 && (
              <span className="text-center">{t('noResults')}</span>
            )}
          </Col>
        </Row>
      )}
      {!results && (
        <div style={styles.notes} className="mt-5">
          <p style={{ ...styles.notesText, maxWidth: isMobile ? '85%' : '60%' }}>
            <strong className="me-1">{t('Note')}:</strong>
            {t('searchPage.limitations')}
          </p>
        </div>
      )}
    </Container>
  );
};

export default SearchPage;

const styles: { [key: string]: React.CSSProperties } = {
  notes: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  notesText: {
    marginTop: '50px',
  },
};
