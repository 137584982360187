import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

void i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'it', 'es-ES', 'ko', 'zh-CN', 'zh-TW', 'ja', 'de', 'ru', 'fr'],
    fallbackLng: 'en',
    // debug: true,
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    missingKeyHandler: function (lng, ns, key) {
      console.warn(`Missing translation key: ${key} in namespace: ${ns} for language: ${lng}`);
    },
  });

export default i18n;
