import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CompDownloadAndPlayVoice from '../../components/DownloadAndPlayVoice';
import { Maker } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';

interface MakerProps {
  data: Maker;
}

const MakerRenderer: React.FC<MakerProps> = ({ data: maker }) => {
  const { t } = useTranslation();
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Link to="#" style={styles.title}>
          <FontAwesomeIcon icon={faUser} className="me-2" style={{ color: '#999' }} />
          {getDefaultText(maker, 'name') || t('unknown')}
        </Link>
      </div>
      <div style={styles.infoContainer}>
        <div style={styles.actionButtons}>
          {getDefaultText(maker, 'description') && (
            <div style={styles.buttonContainer}>
              <CompDownloadAndPlayVoice item={maker} lang={lang} />
            </div>
          )}
        </div>

        <div style={styles.body}>
          <div style={styles.description}>
            {maker ? <ReactMarkdown>{getDefaultText(maker, 'description')}</ReactMarkdown> : <p>{t('unknown')}</p>}
          </div>
          <div style={styles.date}>
            <strong>
              {maker.dateOfBirth || t('unknown')} - {maker.dateOfDeath || t('unknown')}
            </strong>
            <span style={styles.birthPlace}>{maker.birthPlace || ''}</span>
          </div>
          {/* {maker.movements && (
            <div style={styles.movements}>
              <strong>{t('movements')}:</strong> {maker.movements.join(', ')}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '5px',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    marginBottom: '16px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'inherit',
  },
  infoContainer: {
    flex: '1',
    marginRight: '16px',
  },
  actionButtons: {
    display: 'flex',
    gap: '20px',
    marginTop: '10px',
  },
  buttonContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  body: {
    marginTop: '8px',
  },
  description: {
    textAlign: 'justify',
    marginTop: '8px',
  },
  date: {
    marginTop: '8px',
  },
  birthPlace: {
    marginLeft: '16px',
    fontStyle: 'italic',
  },
  movements: {
    marginTop: '16px',
  },
};

export default MakerRenderer;
