import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { gql, useApolloClient } from '@apollo/client';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CONTENT_BASE_URL } from '../constants';
import {
    Artwork, Maker, TextContent, useSynthesizeVoiceMutation, useTranslateTextLazyQuery
} from '../graphql/server-graphql-schema';
import { getFlagIcon } from '../utility/text-util';

interface CompDownloadAndPlayVoiceProps {
  item: Artwork | Maker | null | undefined;
  lang: string;
  variation?: string;
  onUpdate?: (lang: string) => void;
}

const CompDownloadAndPlayVoice: React.FC<CompDownloadAndPlayVoiceProps> = ({ item, lang, variation = 'default', onUpdate }) => {
  if (!item) {
    return null;
  }
  const { t } = useTranslation();

  const [translateText, { error: errorTrans, loading: translating }] = useTranslateTextLazyQuery();
  const client = useApolloClient();

  const [synthesizeVoice, { loading: synthesizingVoice, error: errSynthVoice }] = useSynthesizeVoiceMutation();
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const handleTranslate = async (lang: string) => {
    if (item && item.text && item.__typename && lang !== 'en' && item.text) {
      try {
        const existingText: TextContent = { ...item.text };

        const { data } = await translateText({
          variables: {
            input: {
              ids: [item.id],
              lang,
              type: item.__typename,
              variation: 'default',
            },
          },
        });

        if (data?.translateText) {
          existingText.lang = {
            ...existingText.lang,
            description: {
              ...existingText.lang?.description,
              default: {
                text: data.translateText[0].description || '',
              },
            },
          };
          client.writeFragment({
            id: client.cache.identify(item),
            fragment: gql`
                fragment TranslatedText on ${item.__typename} {
                  text
                }
              `,
            data: {
              text: { ...existingText },
            },
          });

          onUpdate && onUpdate(lang);
        }
      } catch (err) {
        console.error('Error translating text:', err);
      }
    }

    onUpdate && onUpdate(lang);
  };

  const handleSynthesizeVoice = async () => {
    if (!item || !item.id || !item.text || !item.__typename) {
      return;
    }

    try {
      const { data } = await synthesizeVoice({
        variables: {
          input: {
            id: item.id,
            lang: item.text.lang ? lang : 'en',
            type: item.__typename,
            variation,
          },
        },
      });

      if (data?.synthesizeVoice?.url) {
        setAudioUrl(data.synthesizeVoice.url);
      }

      onUpdate && onUpdate(lang);
    } catch (err) {
      console.error('Error synthesizing voice:', err);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center gap-4">
        <div style={styles.container}>
          {!item.text?.['lang'] && lang !== 'en' && (
            <Button variant="outline-primary" onClick={() => void handleTranslate(lang)}>
              {t('translate')} <span className={`flag-icon ${getFlagIcon(lang)} ms-2`}></span>
            </Button>
          )}
          {!audioUrl &&
            item.text?.[lang === 'en' ? 'en' : 'lang']?.description?.default?.text &&
            !item.text?.[lang === 'en' ? 'en' : 'lang']?.description?.default?.audio?.url && (
              <Button variant="outline-primary" onClick={() => void handleSynthesizeVoice()}>
                <FontAwesomeIcon icon={faVolumeUp} />
              </Button>
            )}
          {(translating || synthesizingVoice) && <Spinner animation="border" size="sm" style={styles.spinner} />}
          {(audioUrl || (item.text?.['lang'] || item.text?.['en'])?.description?.default?.audio?.url) && (
            <audio
              controls
              src={audioUrl || CONTENT_BASE_URL + (item.text?.['lang'] || item.text?.['en'])?.description?.default?.audio?.url}
            >
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      </div>
      {(errorTrans || errSynthVoice) && <p style={styles.errorText}>{t('failedToSynthesizeVoice')}</p>}
      {/* <pre>{JSON.stringify(item.text, null, 2)}</pre> */}
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  flagDropdown: {
    background: 'transparent',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '4px 8px',
    fontSize: '1rem',
    cursor: 'pointer',
    appearance: 'none', // Removes the default dropdown arrow for a cleaner look
  },
  spinner: {
    marginLeft: '8px',
  },
  dropdownMenu: {
    minWidth: 'auto',
    width: '3.5rem', // Adjust this value based on the icon size
  },
  errorText: {
    color: 'red',
  },
};

export default CompDownloadAndPlayVoice;
