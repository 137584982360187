import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateMakerPage from './CreateMakerPage';

type AddMakerButtonProps = {
  onMakerAdded: (newMakerId: string, name: string) => void; // Callback when a maker is successfully added
};

export const AddMakerButton: React.FC<AddMakerButtonProps> = ({ onMakerAdded }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleMakerSuccess = (newMakerId: string, name: string) => {
    onMakerAdded(newMakerId, name); // Notify parent about the new maker
    handleCloseModal();
  };

  return (
    <div>
      <Button variant="outline-primary" onClick={handleOpenModal}>
        <FontAwesomeIcon icon={faPlus} title={t('add')} />
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('createNewMaker')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateMakerPage onClose={handleCloseModal} onSuccess={handleMakerSuccess} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
