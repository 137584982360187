import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import CompConfirmDialog from '../../components/CompConfirmDialog';
import { useDeleteArtworkMutation, useGetAllArtworksQuery } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import ArtworkRenderer from './ArtworkRenderer';

const ArtworkListPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId: routeUserId, locationId: routeLocationId } = useParams<{ userId?: string; locationId?: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const auth = useSelector((state: RootState) => state.auth.auth);
  const userId = routeUserId ? auth.id : undefined;
  const lang = useSelector((state: RootState) => state.core.settings.lang);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { data, loading, error, refetch } = useGetAllArtworksQuery({
    variables: {
      page: currentPage,
      limit: itemsPerPage,
      lang,
      userId,
      locationId: routeLocationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    void refetch();
  }, [lang, refetch]);

  const [deleteArtwork] = useDeleteArtworkMutation();
  const [deletingArtworkId, setDeletingArtworkId] = useState<string | null>(null);

  const handleDelete = (artworkId: string) => {
    setDeletingArtworkId(artworkId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteArtwork = async () => {
    if (deletingArtworkId) {
      try {
        await deleteArtwork({
          variables: { id: deletingArtworkId },
        });
        await refetch();
      } catch (error) {
        console.error('Error deleting artwork:', error);
        alert('Failed to delete artwork. Please try again.');
      } finally {
        setDeletingArtworkId(null);
        setShowDeleteConfirmModal(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setDeletingArtworkId(null);
    setShowDeleteConfirmModal(false);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1); // Correctly set the page
  };

  const handleEdit = (artworkId: string) => {
    void navigate(`/edit-artwork/${artworkId}`);
  };

  const totalPages = Math.max(Math.ceil((data?.getAllArtworks?.total || 0) / itemsPerPage), 1);

  const renderPagination = () => (
    <Row className="mt-4">
      <Col className="text-center">
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
          forcePage={currentPage - 1} // Ensure the current page is highlighted
        />
      </Col>
    </Row>
  );

  if (error) {
    return (
      <Container className={isMobile ? 'mt-2' : 'mt-5'}>
        <Alert variant="danger">error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <Row className="mb-4">
        <Col>
          <h4>
            {t('recentlyAdded')}:
            {loading && (
              <span className="ms-2">
                <Spinner animation="border" size="sm" role="status" />
              </span>
            )}
          </h4>
        </Col>
      </Row>
      {data?.getAllArtworks?.artworks.length === 0 ? (
        <div className="text-center">
          <img src="https://via.placeholder.com/150" alt={t('noArtworksAvailable')} />
          <p>{t('noArtworksAvailableMessage')}</p>
        </div>
      ) : (
        <>
          <ListGroup>
            {data?.getAllArtworks?.artworks.map((artwork) => {
              const detailPageRoute = routeUserId ? `/my-artworks/${routeUserId}/${artwork.id}` : `/artworks/${artwork.id}`;
              return (
                <ListGroup.Item key={artwork.id} style={styles.artworkItem}>
                  <ArtworkRenderer
                    data={artwork}
                    auth={auth}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    detailPageRoute={detailPageRoute}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          {renderPagination()}
        </>
      )}
      <CompConfirmDialog
        show={showDeleteConfirmModal}
        onHide={handleCancelDelete}
        onConfirm={confirmDeleteArtwork}
        title={t('confirmDeletion')}
        body={t('confirmDeletionMessage')}
      />
    </Container>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  artworkItem: {
    border: 'none',
    padding: 0,
    marginBottom: 10,
  },
};

export default ArtworkListPage;
