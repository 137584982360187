import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { faBookOpen, faCamera, faPalette, faSearch, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faPeople } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CompFlagAnimation } from './CompFlagAnimation';

export const CompHero: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="position-relative">
      {/* Large Image */}
      <img
        srcSet={`${process.env.PUBLIC_URL}/images/people-talking1_low.jpg 500w,
          ${process.env.PUBLIC_URL}/images/people-talking1_medium.jpg 700w,
          ${process.env.PUBLIC_URL}/images/people-talking1_high.jpg 1200w`}
        sizes="(max-width: 600px) 300px,
          (max-width: 1200px) 900px,
          1200px"
        src={`${process.env.PUBLIC_URL}/images/people-talking1_high.jpg`}
        className="img-fluid rounded shadow w-100"
        alt="People discussing artwork in a museum"
        aria-label="People discussing artwork in a museum"
        loading="lazy"
        style={{ maxHeight: '500px', objectFit: 'cover' }}
      />

      {/* Floating Text Content */}
      <div className="card bg-white shadow-lg mx-auto" style={isMobile ? styles.floatingCardMobile : styles.floatingCard}>
        <h1 className="display-5 fw-bold lh-1 mb-4 text-center">{t('homePage.title')}</h1>
        <p className="lead mb-4 text-center">{t('homePage.description')}</p>

        {/* Bullet Points */}
        <ul className="list-unstyled lead d-none d-lg-block">
          {/* Desktop Version */}
          <li className="mb-3">
            <FontAwesomeIcon icon={faPalette} className="me-2 text-secondary" aria-label="Palette icon" />
            {t('homePage.bulletPoints.learn')} <CompFlagAnimation />
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={faBookOpen} className="me-2 text-success" aria-label="Book icon" />
            {t('homePage.bulletPoints.discover')}
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={faSearch} className="me-2 text-info" aria-label="Search icon" />
            {t('homePage.bulletPoints.uncover')}
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={faCamera} className="me-2 text-warning" aria-label="Camera icon" />
            {t('homePage.bulletPoints.capture')}
          </li>
          <li>
            <FontAwesomeIcon icon={faShareAlt} className="me-2 text-danger" aria-label="Share icon" />
            {t('homePage.bulletPoints.share')}
          </li>
        </ul>

        <ul className="list-unstyled lead d-block d-lg-none">
          {/* Mobile Version */}
          <li className="mb-3">
            <FontAwesomeIcon icon={faBookOpen} className="me-2 text-secondary" aria-label="Palette icon" />
            {t('homePage.bulletPointsMobile.learn')} <CompFlagAnimation />
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={faPeople} className="me-2 text-success" aria-label="Book icon" />
            {t('homePage.bulletPointsMobile.discover')}
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={faSearch} className="me-2 text-info" aria-label="Search icon" />
            {t('homePage.bulletPointsMobile.uncover')}
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={faCamera} className="me-2 text-warning" aria-label="Camera icon" />
            {t('homePage.bulletPointsMobile.capture')}
          </li>
          <li>
            <FontAwesomeIcon icon={faShareAlt} className="me-2 text-danger" aria-label="Share icon" />
            {t('homePage.bulletPointsMobile.share')}
          </li>
        </ul>

        {/* Call-to-Action Section */}
        <div className="mt-5 text-center">
          <h4 className="mb-4">{t('homePage.comingSoon')}</h4>
          <div className="d-flex justify-content-center gap-4 text-muted" style={{ opacity: 0.1 }}>
            <a
              href="https://apps.apple.com/eg/app/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-inline-block"
              aria-label={t('homePage.appStoreAlt')}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`}
                alt={t('homePage.appStoreAlt')}
                className="app-store-icon"
                style={isMobile ? styles.storeIconMobile : styles.storeIcon}
              />
            </a>

            <a
              href="https://play.google.com/apps"
              target="_blank"
              rel="noopener noreferrer"
              className="d-inline-block"
              aria-label={t('homePage.googlePlayAlt')}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/PreRegisterOnGooglePlay_Badge_Web_color_english.png.png`}
                alt={t('homePage.googlePlayAlt')}
                className="google-play-icon"
                style={isMobile ? styles.storeIconMobile : styles.storeIcon}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  floatingCard: {
    maxWidth: '570px',
    borderRadius: '20px',
    marginTop: '-70px',
    zIndex: 100,
    padding: '1.5rem 2.5rem',
  },
  floatingCardMobile: {
    maxWidth: '330px',
    borderRadius: '20px',
    marginTop: '-40px',
    zIndex: 100,
    padding: '1.5rem 1.3rem',
  },
  storeIcon: {
    height: '60px',
    maxWidth: '100%',
  },
  storeIconMobile: {
    height: 'auto',
    width: '150px',
    maxWidth: '100%',
  },
};
