import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as z from 'zod';

import { faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';

import { CONTENT_BASE_URL } from '../../constants';
import {
    EditArtworkInput, GetArtworkByIdQuery, useDeleteMediaMutation, useEditArtworkMutation, useFillInfoArtworkMutation,
    useGetAllMakersAndLocationsQuery, useGetArtworkByIdQuery, usePromoteMediaMutation
} from '../../graphql/server-graphql-schema';
import { resetUpload, setError } from '../../store/coreSlice';
import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';
import { AddLocationButton } from '../location/AddLocationButton';
import { AddMakerButton } from '../maker/AddMakerButton';
import CompDragDropImageUpload from '../Search/ImageSearch/CompDragDropImageUpload';

type MediaList = GetArtworkByIdQuery['getArtworkById']['media'];
// Define schema using zod
const schema = z.object({
  name: z.string(),
  description: z.string(),
  makerId: z.string(),
  archiveId: z.string(),
  makerName: z.string(),
  locationName: z.string(),
  locationId: z.string(),
  dateStarted: z.string().optional(),
  meta: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        try {
          JSON.parse(value);
          return true;
        } catch {
          return false;
        }
      },
      { message: 'Meta must be a valid JSON string' },
    ),
  isPublic: z.boolean(),
});

const EditArtworkPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  if (!id) {
    return <Alert variant="danger">Error: ID is required.</Alert>;
  }

  const isEditMode = true;
  const navigate = useNavigate();
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      makerName: '',
      locationName: '',
      description: '',
      makerId: '',
      archiveId: '',
      locationId: '',
      dateStarted: '',
      meta: '',
      isPublic: false,
    },
  });

  const [media, setMedia] = useState<MediaList>([]);
  const [makers, setMakers] = useState<{ value: string; label: string }[]>([]);
  const [locations, setLocations] = useState<{ value: string; label: string }[]>([]);
  const [visibleDetails, setVisibleDetails] = useState<boolean>(false);

  const { data, loading, error, refetch } = useGetAllMakersAndLocationsQuery({
    variables: { page: 1, limit: 10000, lang: lang },
  });

  const { data: artworkData, loading: loadingArtwork } = useGetArtworkByIdQuery({
    variables: { id: id || '', lang: lang },
    skip: !isEditMode,
  });

  const [editArtwork] = useEditArtworkMutation();
  const [deleteMedia] = useDeleteMediaMutation();
  const [promoteMedia] = usePromoteMediaMutation();
  const [fillInfoArtwork] = useFillInfoArtworkMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    resetState();
  }, [dispatch]);

  const resetState = () => {
    dispatch(setError(null));
    dispatch(resetUpload());
  };

  useEffect(() => {
    if (isEditMode && artworkData?.getArtworkById) {
      const artwork = artworkData.getArtworkById;

      // what is the type of artworkData.getArtworkById?

      if (artwork.media && artwork.media.length > 0) {
        setMedia([...artwork.media]);
      }
      setValue('name', getDefaultText(artwork, 'name') || '');
      setValue('makerName', artwork.makerName || '');
      setValue('locationName', artwork.locationName || '');
      setValue('description', getDefaultText(artwork, 'description') || '');
      setValue('makerId', artwork.maker?.id || '');
      setValue('archiveId', artwork.archiveId || artwork.meta?.archiveId || '');
      setValue('locationId', artwork.location?.id || '');
      setValue('dateStarted', artwork.dateStarted || '');
      setValue('meta', artwork.meta ? JSON.stringify(artwork.meta, null, 2) : '');
      setValue('isPublic', artwork.isPublic || false); // Ensure isPublic is set

      // Set select fields
      if (artwork.maker?.id) {
        const selectedMaker = makers.find((maker) => maker.value === artwork.maker?.id);
        if (selectedMaker) {
          setValue('makerId', selectedMaker.value);
        }
      }

      if (artwork.location?.id) {
        const selectedLocation = locations.find((location) => location.value === artwork.location?.id);
        if (selectedLocation) {
          setValue('locationId', selectedLocation.value);
        }
      }
    }
  }, [artworkData, isEditMode, setValue]);

  useEffect(() => {
    if (data?.getAllMakers?.makers) {
      const makerOptions = data.getAllMakers.makers.map((maker) => ({
        value: maker.id,
        label: getDefaultText(maker, 'name') || 'Unknown Maker',
      }));
      setMakers(makerOptions);
    }

    if (data?.getAllLocations?.locations) {
      const locationOptions = data.getAllLocations.locations.map((location) => ({
        value: location.id,
        label: location.name,
      }));
      setLocations(locationOptions);
    }
  }, [data]);

  const createArtworkData = (formData: any) => {
    const parsedMeta = formData.meta ? JSON.parse(formData.meta) : null;

    const input: EditArtworkInput = {
      archiveId: formData.archiveId,
      text: { lang: lang, name: formData.name, description: formData.description },
      makerId: formData.makerId,
      locationId: formData.locationId,
      makerName: formData.makerName,
      locationName: formData.locationName,
      dateStarted: formData.dateStarted,
      meta: parsedMeta,
      isPublic: formData.isPublic,
    };

    // Remove locationId and makerId if they are empty strings
    if (input.locationId === '') {
      delete input.locationId;
    }

    if (input.makerId === '') {
      delete input.makerId;
    }

    return input;
  };

  const handleSaveArtwork = async (formData: any, actionType: 'save' | 'fillInfo') => {
    try {
      const input = createArtworkData(formData);

      if (actionType === 'fillInfo') {
        await fillInfoArtwork({
          variables: {
            input: { artwork: input, level: 1, id },
          },
        });
        window.location.reload();
      } else {
        if (isEditMode) {
          await editArtwork({
            variables: {
              id,
              input,
            },
          });
          void navigate('/artworks');
        }
      }
    } catch (error) {
      console.error('Error saving artwork:', error);
    }
  };

  const handleMakerAdded = (newMakerId: string, name: string) => {
    setMakers((prev) => [...prev, { value: newMakerId, label: name }]);
    setValue('makerId', newMakerId);
    void refetch();
  };

  const handleLocationAdded = (newLocationId: string, name: string) => {
    setLocations((prev) => [...prev, { value: newLocationId, label: name }]);
    setValue('locationId', newLocationId);
    void refetch();
  };

  const handleDelete = async (mediaId: string) => {
    try {
      await deleteMedia({ variables: { mediaId } });
      setMedia((prev) => (prev || []).filter((m) => m.id !== mediaId)); // Update media state
    } catch (err) {
      console.error(err);
      alert('Failed to delete media.');
    }
  };

  const handlePromote = async (mediaId: string) => {
    try {
      await promoteMedia({ variables: { mediaId } });
      // Optionally refetch or update the media list in the state
    } catch (err) {
      console.error(err);
      alert('Failed to promote media.');
    }
  };

  if (loading || (isEditMode && loadingArtwork)) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Failed to load data: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h2>{isEditMode ? 'Edit Artwork' : 'Create New Artwork'}</h2>
      <Form onSubmit={handleSubmit((data) => handleSaveArtwork(data, 'save'))}>
        <Card className="mb-4 p-3">
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formMakerId">
                <Form.Label>Maker</Form.Label>
                <div className="d-flex justify-content-between">
                  <div className="w-100 me-3">
                    <Controller
                      name="makerId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={makers}
                          placeholder="Select a maker..."
                          onChange={(selected) => {
                            setValue('makerId', selected?.value || '');
                            clearErrors('makerId');
                          }}
                          value={makers.find((option) => option.value.toLowerCase() === field.value.toLowerCase()) || null}
                          isClearable
                        />
                      )}
                    />
                    {errors.makerId && <Alert variant="danger">{errors.makerId.message}</Alert>}
                  </div>
                  <AddMakerButton onMakerAdded={handleMakerAdded} />
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formLocationId">
                <Form.Label>Location</Form.Label>
                <div className="d-flex justify-content-between">
                  <div className="w-100 me-3">
                    <Controller
                      name="locationId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={locations}
                          placeholder="Select a location..."
                          onChange={(selected) => {
                            setValue('locationId', selected?.value || '');
                            clearErrors('locationId');
                          }}
                          value={locations.find((option) => option.value.toLowerCase() === field.value.toLowerCase()) || null}
                          isClearable
                        />
                      )}
                    />
                    {errors.locationId && <Alert variant="danger">{errors.locationId.message}</Alert>}
                  </div>
                  <AddLocationButton onLocationAdded={handleLocationAdded} />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={6}>
              <Form.Group controlId="formMakerName">
                <Form.Label>Artist Name</Form.Label>
                <Form.Control {...register('makerName')} />
                {errors.name && <Alert variant="danger">{errors.name.message}</Alert>}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="formMakerName">
                <Form.Label>Location Name</Form.Label>
                <Form.Control {...register('locationName')} />
                {errors.name && <Alert variant="danger">{errors.name.message}</Alert>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control {...register('name')} />
                {errors.name && <Alert variant="danger">{errors.name.message}</Alert>}
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group controlId="formDateStarted">
                <Form.Label>Year Created</Form.Label>
                <Form.Control {...register('dateStarted')} />
                {errors.name && <Alert variant="danger">{errors.name.message}</Alert>}
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group controlId="formArchiveId">
                <Form.Label>Archive Id</Form.Label>
                <Form.Control {...register('archiveId')} />
                {errors.name && <Alert variant="danger">{errors.name.message}</Alert>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={6}>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control {...register('description')} as="textarea" rows={6} />
                {errors.description && <Alert variant="danger">{errors.description.message}</Alert>}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="formMeta">
                <Form.Label>Meta</Form.Label>
                <Form.Control {...register('meta')} as="textarea" rows={6} />
                {errors.meta && <Alert variant="danger">{errors.meta.message}</Alert>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={6}>
              <div>
                <Button variant="link" size="sm" onClick={() => setVisibleDetails((prev) => (prev = !prev))}>
                  {visibleDetails ? 'Hide Details' : 'Show Details'}
                </Button>
              </div>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="formIsPublic">
                <Form.Label>Public</Form.Label>
                <Form.Check {...register('isPublic')} type="checkbox" />
                {errors.isPublic && <Alert variant="danger">{errors.isPublic.message}</Alert>}
              </Form.Group>
            </Col>
          </Row>

          <div>
            <Button variant="primary" type="submit">
              Save Artwork
            </Button>
            <Button variant="secondary" onClick={handleSubmit((data) => handleSaveArtwork(data, 'fillInfo'))}>
              Fill Info
            </Button>
          </div>
        </Card>
      </Form>

      <div>
        {media && media.length > 0 && (
          <div>
            <h5>Images:</h5>
            <div style={styles.mediaContainer}>
              {media
                .sort((a, b) => a.order - b.order) // Sort media by order
                .map((m, index) => (
                  <div key={index} style={styles.mediaItem}>
                    <img src={CONTENT_BASE_URL + m.urls?.thumb} alt={`Uploaded ${index + 1}`} style={styles.mediaImage} />
                    <div style={styles.mediaButtons}>
                      <Button
                        className="me-2"
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(m.id)}
                        title="Delete Media"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <Button
                        className="me-2"
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handlePromote(m.id)}
                        title="Set as Primary Image"
                      >
                        <FontAwesomeIcon icon={faStar} />
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="mt-5">
        <CompDragDropImageUpload />
      </div>

      {visibleDetails && <pre>{JSON.stringify(artworkData?.getArtworkById, null, 2)}</pre>}
    </Container>
  );
};

export default EditArtworkPage;

const styles: { [key: string]: React.CSSProperties } = {
  mediaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  mediaItem: {
    position: 'relative',
    width: '150px',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mediaImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  mediaButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
  mediaButton: {
    cursor: 'pointer',
  },
  mediaDelete: {},
  mediaPromote: {},
};
