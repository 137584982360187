import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SUPPORTED_LANGS } from '../../constants';
import artworks from './artworks.json';

export const CompSampleArtworks: React.FC = () => {
  const { t } = useTranslation();

  const [itemIndex, setItemIndex] = useState(5);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    const interval = setInterval(() => {
      setItemIndex((prevIndex) => (prevIndex + 1) % 10);
    }, 3000);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, []);

  const handleFlagClick = (index: number) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setItemIndex(index);
  };

  const handleAudioPlay = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  const artwork = artworks[itemIndex];

  return (
    <div
      style={{
        ...styles.container,
        padding: isMobile ? '10px' : '25px',
        height: isMobile ? '670px' : '370px',
        overflowY: isMobile ? 'hidden' : 'auto',
      }}
    >
      <div style={isMobile ? styles.flagsContainerMobile : styles.flagsContainer}>
        {SUPPORTED_LANGS.map((lang, index) => (
          <span
            key={index}
            className={`flag-icon ${lang.flag}`}
            style={{
              cursor: 'pointer',
              transform: itemIndex === index ? 'scale(1.5)' : 'scale(1)',
              transition: 'transform 0.3s',
              fontSize: isMobile ? '16px' : '20px', // Set the size of the flags here
              marginLeft: isMobile ? '0px' : '5px',
            }}
            onClick={() => handleFlagClick(index)}
          ></span>
        ))}
      </div>
      <div style={styles.header}>
        <div style={styles.title}>{artwork.title}</div>
        <div className="mb-3">
          {artwork.artist}, {artwork.year}
        </div>
      </div>
      <div style={styles.infoContainer}>
        <div style={styles.actionButtons}>
          <span className={`flag-icon ${SUPPORTED_LANGS[itemIndex].flag}`}></span>
          <audio controls src={artwork.audioSrc} preload="none" onPlay={handleAudioPlay}>
            {t('audioNotSupported')}
          </audio>
        </div>

        <div style={styles.body}>
          <img
            style={styles.image}
            src="https://storage.googleapis.com/static.arthista.com/thumb/7af8421d-5134-4759-abd5-a02f45c67732"
          />
          <div style={styles.description}>{artwork.description}</div>

          <div style={styles.location}>
            {t('createdIn')}: <FontAwesomeIcon icon={faLocationDot} style={styles.icon} /> {artwork.location}
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', overflowX: 'hidden' }}>
        <div style={{ position: 'sticky', top: '50%', transform: 'translateY(-50%)' }}>
          {Array.from({ length: 10 }, (_, i) => i).map((card, index) => (
            <motion.div
              key={index}
              style={{
                position: 'absolute',
                left: `${index * 200}px`,
                width: '150px',
                height: '200px',
                backgroundColor: 'lightblue',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
              initial={{ opacity: 0, x: 300 }}
              animate={{
                opacity: itemIndex === index ? 1 : 0,
                x: itemIndex === index ? 0 : 300,
                transition: { type: 'spring', stiffness: 50 },
              }}
            ></motion.div>
          ))}
        </div>
      </div>
      {/* {artwork.media && <CompImageThumbs artwork={artwork} showDate={false} />} */}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #ccc',
    borderRadius: '8px',
    flexWrap: 'wrap',
    height: '500px', // Fixed height
    overflowY: 'auto', // Enable vertical scrolling if content overflows
  },
  flagsContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    gap: '10px',
  },
  flagsContainerMobile: {
    marginBottom: '10px',
    position: 'relative',
    display: 'flex',
    gap: '10px',
  },
  imageContainer: {
    flex: '0 0 150px',
    marginLeft: '16px',
  },
  infoContainer: {
    flex: '1',
  },
  icon: {
    marginRight: '8px',
  },
  body: {
    marginTop: '15px',
    overflow: 'hidden', // Ensure the container clears the floated image
  },
  image: {
    float: 'right',
    margin: '0 0 10px 30px',
  },
  description: {
    marginTop: '8px',
    textAlign: 'justify',
  },
  location: {
    marginTop: '8px',
    fontStyle: 'italic',
  },
  additionalInfo: {
    marginTop: '8px',
  },
  relations: {
    marginTop: '16px',
  },
  header: {
    width: '100%',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  buttonContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  imageThumbsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};
