import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    CreateArtworkSimpleInput, useCreateArtworkSimpleMutation, useFillInfoArtworkMutation
} from '../../../graphql/server-graphql-schema';
import { resetUpload, setArtwork, setError } from '../../../store/coreSlice';
import { RootState } from '../../../store/store';
import ArtworkRenderer from '../../artwork/ArtworkRenderer';

type CompUploadOcrArtworkProps = {};

const CompUploadOcrArtwork: React.FC<CompUploadOcrArtworkProps> = () => {
  const { t } = useTranslation();
  const ocrArtwork = useSelector((state: RootState) => state.core.ocrArtwork);
  const artworkId = useSelector((state: RootState) => state.core.artworkId);
  const artwork = useSelector((state: RootState) => state.core.artwork);
  const currentLocation = useSelector((state: RootState) => state.core.currentLocation);
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  const dispatch = useDispatch();
  const [createArtworkSimple] = useCreateArtworkSimpleMutation();
  const [fillInfoArtwork] = useFillInfoArtworkMutation();

  const handleUploadArtwork = async () => {
    if (!ocrArtwork) {
      return;
    }

    try {
      // Prepare localizedText array
      const localizedText = (ocrArtwork.artworkNames || []).map((artworkName) => ({
        lang: artworkName.lang,
        name: artworkName.text,
        description: ocrArtwork.description?.find((desc) => desc.lang === artworkName.lang)?.text || '',
        makerName: ocrArtwork.artistNames?.find((artist) => artist.lang === artworkName.lang)?.text || '',
        locationName: ocrArtwork.artworkLocation || '',
      }));

      const input: CreateArtworkSimpleInput = {
        ...(artworkId && { id: artworkId }),

        locationId: currentLocation?.id || '',
        dateStarted: ocrArtwork.dateOfCreation || '',
        archiveId: ocrArtwork.archiveId,
        meta: {
          material: ocrArtwork.material,
          dateOfBirthOfArtist: ocrArtwork.dateOfBirthOfArtist,
          dateOfDeathOfArtist: ocrArtwork.dateOfDeathOfArtist,
          locationOfBirthOfArtist: ocrArtwork.locationOfBirthOfArtist,
          locationOfDeathOfArtist: ocrArtwork.locationOfDeathOfArtist,
        },
        text: localizedText,
      };

      // Upsert the artwork
      const result = await createArtworkSimple({
        variables: {
          input,
        },
      });

      if (result?.data?.createArtworkSimple && result?.data?.createArtworkSimple.id) {
        const artwork = result?.data?.createArtworkSimple;

        const text = {
          lang: artwork.text?.en ? 'en' : lang,
          name: artwork.text?.en?.name?.default?.text || '',
          description: artwork.text?.en?.description?.default?.text || '',
        };

        const filledArtwork = await fillInfoArtwork({
          variables: {
            input: { artwork: { ...artwork, text }, level: 1, id: artwork.id },
          },
        });
        if (filledArtwork?.data?.fillInfoArtwork) {
          dispatch(setArtwork(filledArtwork?.data?.fillInfoArtwork));
        }
      } else {
        dispatch(resetUpload());

        dispatch(setError(null));
      }

      // Navigate to the artworks list page after success
    } catch (error) {
      console.error('Error uploading artwork:', error);
      dispatch(setError(t('errorMessages.failedToUploadArtwork')));
    }
  };

  const handleStartAgain = () => {
    dispatch(resetUpload());
  };

  return (
    <div className="w-100 mt-3">
      <div className="d-flex justify-content-end">
        {!artwork ? (
          <Button variant="primary" onClick={handleUploadArtwork} disabled={!ocrArtwork}>
            <FontAwesomeIcon icon={faSearch} className="me-2" />
            {t('Search')}
          </Button>
        ) : (
          <Button variant="outline-primary" onClick={handleStartAgain}>
            {t('buttons.startAgain')}
          </Button>
        )}
      </div>
      <div className="mt-3">
        {artwork && (
          <ArtworkRenderer
            data={artwork}
            auth={{ role: 'USER', id: '' }}
            onEdit={() => {}}
            onDelete={() => {}}
            detailPageRoute={`/artworks/${artwork.id}`}
          />
        )}
      </div>
    </div>
  );
};

export default CompUploadOcrArtwork;

const styles: { [key: string]: React.CSSProperties } = {};
