import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../store/store';

const CompRequireLoginAlert: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  const { t } = useTranslation();

  if (auth.email) {
    return null;
  }

  return (
    <Alert variant="warning" dismissible>
      {t('requireLogin')}
    </Alert>
  );
};

export default CompRequireLoginAlert;
