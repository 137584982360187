import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/store';

// Mocked function that provides highlight positions based on step
const getHighlightPosition = (step: number) => {
  switch (step) {
    case 1:
      return { xPercent: 17, yPercent: 39, widthPercent: 16, heightPercent: 32 };
    case 2:
      return { xPercent: 40, yPercent: 4, widthPercent: 20, heightPercent: 85 };
    case 3:
      return { xPercent: 0, yPercent: 0, widthPercent: 0, heightPercent: 0 }; // No highlight for step 3
    default:
      return { xPercent: 0, yPercent: 0, widthPercent: 0, heightPercent: 0 };
  }
};

const CompAddArtworkImageHeader: React.FC = () => {
  const { t } = useTranslation();

  const step = useSelector((state: RootState) => state.core.step);
  const [highlightPosition, setHighlightPosition] = useState(getHighlightPosition(0)); // Start with no highlight

  useEffect(() => {
    setHighlightPosition(getHighlightPosition(step));
  }, [step]);

  const imgRef = useRef<HTMLImageElement>(null);

  // We track the image’s rendered size:
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!imgRef.current) return;

    // A function that updates the stored image size.
    const updateSize = () => {
      if (!imgRef.current) return;
      setImgSize({
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      });
    };

    // Call it once to set initial values.
    updateSize();

    // Use ResizeObserver (if supported) to listen to image size changes.
    const observer = new ResizeObserver(() => updateSize());
    observer.observe(imgRef.current);

    // Cleanup.
    return () => {
      observer.disconnect();
    };
  }, []);

  // Convert percentages to actual pixels based on the image’s size
  const highlightWidth = (highlightPosition.widthPercent / 100) * imgSize.width;
  const highlightHeight = (highlightPosition.heightPercent / 100) * imgSize.height;
  const highlightLeft = (highlightPosition.xPercent / 100) * imgSize.width;
  const highlightTop = (highlightPosition.yPercent / 100) * imgSize.height;

  return (
    <div
      style={{
        // The parent wrapper must be relative so the highlight is over the image
        position: 'relative',
        display: 'inline-block', // ensures highlight overlays exactly over the image
      }}
    >
      {/* The Image */}
      <img
        ref={imgRef}
        src={`${process.env.PUBLIC_URL}/images/museumAddArt_low.png`}
        alt={t('artwork')}
        style={{
          width: '100%',
          maxHeight: '200px',
          objectFit: 'contain',
          display: 'block',
        }}
      />

      {/* The Highlight */}
      {step !== 0 && step !== 3 && (
        <motion.div
          animate={{
            width: highlightWidth,
            height: highlightHeight,
            left: highlightLeft,
            top: highlightTop,
          }}
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            boxShadow: '0 0 10px 5px rgba(255, 0, 0, 0.7)',
            border: '2px solid rgba(255, 0, 0, 0.5)',
            backgroundColor: 'transparent',
          }}
          transition={{ duration: 0.5 }} // Adjust duration as needed
        />
      )}
    </div>
  );
};

export default CompAddArtworkImageHeader;
