import React, { useEffect, useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CONTENT_BASE_URL, MAX_IMAGE_SIZE_MB } from '../../../constants';
import { MediaType, useAddMediaMutation, useGenerateSignedUploadUrlLazyQuery } from '../../../graphql/server-graphql-schema';
import { addMedia, setArtworkId, setError } from '../../../store/coreSlice';
import { RootState } from '../../../store/store';
import { uploadFileToGCS } from '../../../utility/uploadFile-util';

type CompDragDropImageUploadProps = {};

const CompDragDropImageUpload: React.FC<CompDragDropImageUploadProps> = () => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const error = useSelector((state: RootState) => state.core.error);
  const media = useSelector((state: RootState) => state.core.media);
  const artworkId = useSelector((state: RootState) => state.core.artworkId);
  const resetCounter = useSelector((state: RootState) => state.core.resetCounter);

  const [processUploadedMedia] = useAddMediaMutation();
  const [generateSignedUploadUrl] = useGenerateSignedUploadUrlLazyQuery({ fetchPolicy: 'network-only' });

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    resetState();
  }, [resetCounter]);

  const resetState = () => {
    setUploading(false);
    setUploadProgress(0); // Reset upload progress
    dispatch(setError(null));
  };

  const handleProcessUploadedMedia = async (fileName: string) => {
    const result = await processUploadedMedia({
      variables: {
        input: {
          artworkId: id || artworkId,
          fileName,
          type: MediaType.Image,
        },
      },
    });

    if (result.data?.addMedia) {
      const media = result.data.addMedia.media;
      const artworkId = result.data.addMedia.artworkId;

      // Write the media to the Redux store
      if (media) {
        dispatch(addMedia(media));
      }

      if (artworkId) {
        dispatch(setArtworkId(artworkId));
      }
    } else {
      throw new Error('Failed to process uploaded media');
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    dispatch(setError(null));

    if (acceptedFiles.length === 0) {
      dispatch(setError(t('errorMessages.noFilesSelected')));
      return;
    }

    try {
      setUploading(true);
      setUploadProgress(0); // Reset progress
      for (const file of acceptedFiles) {
        let fileToUpload: File | Blob = file;

        if (file.size > MAX_IMAGE_SIZE_MB) {
          dispatch(setError(t('errorMessages.fileTooBig')));
          setUploading(false);
          return;
        }

        const fileName = await uploadFileToGCS(fileToUpload, generateSignedUploadUrl, (progress) => {
          setUploadProgress(progress); // Update progress
        });

        await handleProcessUploadedMedia(fileName);

        dispatch(setError(null));
      }
    } catch (uploadError) {
      console.error(uploadError);
      dispatch(setError(t('errorMessages.failedToUploadFiles')));
    }

    setUploading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
  });

  return (
    <>
      {!isMobile && (
        <>
          <h5 className="mb-5 mt-1 text-center">{t('takePictureOfArtwork')}</h5>
          <div style={styles.wrapper}>
            <img src={`${process.env.PUBLIC_URL}/images/hole-s.png`} style={styles.image} title={t('upload')} />
            <div {...getRootProps()} style={styles.uploadArea}>
              <input {...getInputProps()} />
            </div>
          </div>
        </>
      )}
      <div className="text-center my-5">
        <Button variant="primary" style={styles.uploadButton} {...getRootProps()}>
          <FontAwesomeIcon icon={faCamera} className="me-2" /> {t('buttons.picture')}
        </Button>
        <input {...getInputProps()} style={styles.hiddenInput} />
      </div>
      <div className="mt-2">
        {uploading && (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} spin /> {t('uploading')}
            <ProgressBar now={uploadProgress} className="my-3" style={styles.progressBar} />
          </div>
        )}
        {error && <div style={styles.errorText}>{error}</div>}
      </div>
      {media.length > 0 && (
        <div style={styles.previewContainer}>
          <div style={styles.mediaGrid}>
            {media.map(
              (m, index) =>
                m.urls?.thumb && (
                  <img
                    key={index}
                    src={CONTENT_BASE_URL + m.urls?.thumb}
                    alt={`${t('uploaded')} ${index + 1}`}
                    style={styles.previewImage}
                  />
                ),
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CompDragDropImageUpload;

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  uploadArea: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.2)',
    // border: '2px dashed #cccccc',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
  },
  previewContainer: {
    margin: '0 auto',
    marginTop: '20px',
  },
  mediaGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
  },
  previewImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  icon: {
    fontSize: '24px',
  },
  uploadButton: {
    minWidth: 140,
  },
  hiddenInput: {
    display: 'none',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
  progressBar: {
    maxWidth: '500px',
    margin: '15px auto',
  },
};
