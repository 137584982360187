import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Artwork, useGetAllArtworkTextsQuery } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';

const TranslatePage: React.FC = () => {
  const lang = useSelector((state: RootState) => state.core.settings.lang);
  const { data, loading, error } = useGetAllArtworkTextsQuery({
    variables: {
      page: 1,
      limit: 10,
      lang,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [formData, setFormData] = useState({ formSource: '', formTranslations: '' });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      alert(`Failed to copy text: ${(err as Error).message}`);
    }
  };

  const generateSourceText = () => {
    if (!data?.getAllArtworks?.artworks) return;

    let sourceLines = '';
    data.getAllArtworks.artworks.forEach((artwork, index) => {
      const name = artwork.text?.en?.name?.default?.text || '';
      const description = artwork.text?.en?.description?.default?.text || '';
      sourceLines += `${index + 1}n>${name}\n${index + 1}d>${description}\n`;
      console.log(`${index + 1}n>${name}\n${index + 1}d>${description}\n`);
    });

    setFormData({ ...formData, formSource: sourceLines.trim() });
  };

  const processResults = () => {
    const translations = formData.formTranslations.split('\n');
    const results = [];

    if (translations.length % 2 !== 0) {
      alert('Translations input is not in the correct format.');
      return;
    }

    for (let i = 0; i < translations.length; i += 2) {
      const nameLine = translations[i];
      const descriptionLine = translations[i + 1];

      const nameMatch = nameLine.match(/^(\d+)n>(.*)$/);
      const descriptionMatch = descriptionLine.match(/^(\d+)d>(.*)$/);

      if (nameMatch && descriptionMatch) {
        const id = data?.getAllArtworks?.artworks[parseInt(nameMatch[1]) - 1]?.id;
        const name = nameMatch[2];
        const description = descriptionMatch[2];

        if (id) {
          results.push({ id, name, description });
        }
      }
    }

    console.log(results);
  };

  if (loading) {
    return (
      <Container className="mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h4>Artwork Descriptions</h4>
      <Button onClick={generateSourceText}>Generate Source Text</Button>
      <Form>
        <section className="border rounded bg-body-tertiary p-5 mt-3">
          <Form.Group as={Row} controlId="formSource" className="mb-2">
            <Form.Label column sm="2">
              Source Text
              <Button variant="link" type="button" onClick={() => copyToClipboard(formData.formSource)} className="p-0 ms-2">
                Copy
              </Button>
            </Form.Label>
            <Col sm="10">
              <Form.Control
                rows={10}
                as="textarea"
                name="formSource"
                autoComplete="off"
                value={formData.formSource}
                onChange={handleFormChange}
              />
            </Col>
          </Form.Group>
        </section>

        <section className="border rounded bg-body-tertiary p-5 mt-3">
          <Form.Group as={Row} controlId="formTranslations" className="mb-2">
            <Form.Label column sm="2">
              Translations
            </Form.Label>
            <Col sm="10">
              <Form.Control
                rows={10}
                as="textarea"
                name="formTranslations"
                value={formData.formTranslations}
                onChange={handleFormChange}
                placeholder="Paste translated sentences here"
              />
            </Col>
          </Form.Group>
        </section>
        <Button onClick={processResults}>Process Results</Button>
      </Form>
      <ListGroup>
        {data?.getAllArtworks?.artworks.map((artwork: Artwork) => (
          <ListGroup.Item key={artwork.id}>
            <h5>{artwork.text?.en?.name?.default?.text || 'No name available'}</h5>
            <p>{artwork.text?.en?.description?.default?.text || 'No description available'}</p>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default TranslatePage;
