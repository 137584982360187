import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { SUPPORTED_LANGS } from '../../constants';

export const CompFlagAnimation: React.FC = () => {
  const [currentFlagIndex, setCurrentFlagIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFlagIndex((prevIndex) => (prevIndex + 1) % SUPPORTED_LANGS.length);
    }, 1000); // Change flag every second
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.span
      key={SUPPORTED_LANGS[currentFlagIndex].code}
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: [0.8, 1.5], opacity: 0.8 }}
      exit={{ scale: 0.8, opacity: 0 }}
      transition={{ duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
      className={`ms-2 flag-icon ${SUPPORTED_LANGS[currentFlagIndex].flag}`}
    />
  );
};
