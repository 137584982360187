import 'flag-icon-css/css/flag-icon.min.css';

import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import Navbar from 'react-bootstrap/esm/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { faSearch, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../store/store';
import CompLanguageDropdownComponent from './CompLanguageDropdownComponent';

const NavBarComponent: React.FC = () => {
  const { t } = useTranslation();
  const auth = useSelector((state: RootState) => state.auth.auth);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleLinkClick = (path: string) => {
    setIsExpanded(false);
    if (location.pathname !== path) {
      void navigate(path);
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="py-2 bg-body-tertiary navbar-custom reduced-height-navbar"
      expanded={isExpanded}
    >
      <Container>
        {/* Logo */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo128.png`}
            alt="ArtHista"
            width="auto"
            height="50"
            className="d-none d-md-inline-block align-middle"
          />
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo64.png`}
            alt="ArtHista"
            width="auto"
            height={isMobile ? 40 : 50}
            className="d-inline-block d-md-none align-middle"
          />

          {/* Site Name */}
          <div className="d-flex align-items-center mx-auto">
            <h1
              className="display-6 fw-bold lh-1 ms-3 mb-0 mt-md-0 text-center"
              style={{
                marginRight: 'auto',
                fontSize: isMobile ? 'calc(1.2rem + 1vw)' : undefined,
              }}
            >
              Art
              <span className="fst-italic" style={{ color: '#6c757d', opacity: 0.7 }}>
                Hista
              </span>
            </h1>
          </div>
        </Navbar.Brand>

        {/* Search Icon */}
        {isMobile && (
          <Nav.Link as={Link} onClick={() => handleLinkClick('/search')} to="/search" className="text-decoration-none">
            <FontAwesomeIcon size="lg" icon={faSearch} className="me-1" style={{ color: 'gray' }} />
          </Nav.Link>
        )}

        {/* Navbar Toggle for Mobile */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsExpanded(!isExpanded)} />

        <Navbar.Collapse id="responsive-navbar-nav" style={{ marginBottom: isExpanded && isMobile ? '30px' : '0' }}>
          {/* Navigation Links */}
          <Nav className="ms-auto mt-3 mt-md-0 nav-links">
            <Nav.Link as={Link} onClick={() => handleLinkClick('/')} to="/">
              {t('Home')}
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/search')} to="/search">
              {t('Search')}
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/artworks')} to="/artworks">
              {t('Artworks')}
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/makers')} to="/makers">
              {t('Artists')}
            </Nav.Link>
            <CompLanguageDropdownComponent />
          </Nav>

          {/* User Section */}
          <Nav className="ms-auto mt-3 me-3 mt-md-0 nav-links">
            {auth.role === 'ADMIN' && (
              <NavDropdown title={<>Admin</>} id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to="/appsettings" onClick={() => handleLinkClick('/appsettings')}>
                  {t('App Settings')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/users" onClick={() => handleLinkClick('/users')}>
                  {t('Users')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} onClick={() => handleLinkClick('/locations')} to="/locations">
                  {t('Locations')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/translate" onClick={() => handleLinkClick('/translate')}>
                  {t('Translate')}
                </NavDropdown.Item>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/locations')} to="/locations">
                  {t('Locations')}
                </Nav.Link>
              </NavDropdown>
            )}

            {!auth.email ? (
              <Nav.Link as={Link} to="/login" onClick={() => handleLinkClick('/login')} className="text-decoration-none">
                <FontAwesomeIcon size="lg" icon={faUserCircle} className="me-1" style={{ color: 'gray' }} />
                {t('Login')}
              </Nav.Link>
            ) : (
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faUserCircle} className="me-1" style={{ color: 'gray' }} />
                    {t('Account')}
                  </>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to={`/my-artworks/${auth.id}`}>
                  {t('My Artworks')}
                </NavDropdown.Item>

                {/* <NavDropdown.Item as={Link} to="/settings" onClick={() => handleLinkClick('/settings')}>
                  {t('Settings')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/archive" onClick={() => handleLinkClick('/archive')}>
                  {t('History')}
                </NavDropdown.Item> */}
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/login" onClick={() => handleLinkClick('/login')}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-1" style={{ color: 'gray' }} />
                  {!auth.email ? t('Login') : t('Logout')}
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;
