import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Artwork, Media, OcrArtwork } from '../graphql/server-graphql-schema';
import { ACTION_ARTWORK } from './actionsArtwork';
import { ACTION_MEDIA } from './actionsMedia';
import { ACTION_SETTINGS } from './actionsSettings';

export interface CoreState {
  ocrArtwork: Partial<OcrArtwork> | null;
  artworkId: string | null;
  artwork: Artwork | null;
  media: Media[];
  error: string | null;

  settings: {
    transLangs: string[];
    lang: string;
  };

  resetCounter: number; // Add resetCounter to the state
  fromMinimal: boolean; // Add fromMinimal state
  step: number; // Add step to the state
  currentLocation: { id: string; name: string } | null;
  logs: string[]; // Add logs to the state
  artworks: any[]; // Add artworks to the state
  makers: any[]; // Add makers to the state
}

export const initialState: CoreState = {
  ocrArtwork: null,
  artwork: null,
  artworkId: null,
  media: [],
  error: null,

  settings: {
    transLangs: ['en', 'es', 'ko'],
    lang: 'en',
  },
  resetCounter: 0, // Initialize resetCounter
  fromMinimal: false, // Initialize fromMinimal
  step: 1, // Initialize step
  currentLocation: null,
  logs: [], // Initialize logs
  artworks: [], // Initialize artworks
  makers: [], // Initialize makers
};

const sentenceSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    upgradeStore: (state: CoreState) => {
      state.settings = {
        transLangs: ['en', 'es', 'ko'],
        lang: 'en',
      };
    },
    ...ACTION_SETTINGS,
    ...ACTION_ARTWORK,
    ...ACTION_MEDIA,

    setError: (state: CoreState, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    resetUpload: (state: CoreState) => {
      state.ocrArtwork = null;
      state.artwork = null;
      state.artworkId = null;
      state.media = [];
      state.resetCounter += 1; // Increment resetCounter
      state.fromMinimal = false;
      state.step = 1;
    },
    setFromMinimal: (state: CoreState, action: PayloadAction<boolean>) => {
      state.fromMinimal = action.payload;
    },
    setStep: (state: CoreState, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setCurrentLocation: (state: CoreState, action: PayloadAction<{ id: string; name: string } | null>) => {
      state.currentLocation = action.payload;
    },
    addLog: (state: CoreState, action: PayloadAction<string>) => {
      state.logs.push(action.payload);
    },
    clearLogs: (state: CoreState) => {
      state.logs = [];
    },
    setArtwork: (state: CoreState, action: PayloadAction<Artwork>) => {
      state.artwork = action.payload;
    },
    setArtworks: (state: CoreState, action: PayloadAction<any[]>) => {
      state.artworks = action.payload;
    },
    setMakers: (state: CoreState, action: PayloadAction<any[]>) => {
      state.makers = action.payload;
    },
  },
});

export const {
  upgradeStore,
  updateSettings,
  setArtwork,
  updateOcrArtwork,
  resetUpload,
  setArtworkId,
  addMedia,
  updateMedia,
  deleteMedia,
  setError,
  setFromMinimal,
  setStep,
  setCurrentLocation,
  addLog, // Export addLog action
  clearLogs, // Export clearLogs action
  setArtworks, // Export setArtworks action
  setMakers, // Export setMakers action
} = sentenceSlice.actions;

export default sentenceSlice.reducer;
