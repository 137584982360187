import { LazyQueryExecFunction } from '@apollo/client';

import { Exact, GenerateSignedUploadUrlInput, GenerateSignedUploadUrlQuery } from '../graphql/server-graphql-schema';

export const uploadToSignedUrl = (
  file: File | Blob,
  signedUrl: string,
  onProgress: (percentage: number) => void,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(percentage);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject(new Error(`Upload failed with status ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error('Upload failed'));
    };

    xhr.open('PUT', signedUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};

export const uploadFileToGCS = async (
  file: File | Blob,
  generateSignedUploadUrl: LazyQueryExecFunction<
    GenerateSignedUploadUrlQuery,
    Exact<{
      input: GenerateSignedUploadUrlInput;
    }>
  >,
  onProgress: (progress: number) => void, // Add progress callback
): Promise<string> => {
  try {
    // Generate signed upload URL
    const { data } = await generateSignedUploadUrl({
      variables: {
        input: {
          contentType: file.type || 'application/octet-stream',
        },
      },
    });

    if (data?.generateSignedUploadUrl) {
      const { signedUrl, fileName } = data.generateSignedUploadUrl;

      // Upload the file to Google Cloud Storage
      await uploadToSignedUrl(file, signedUrl, onProgress); // Pass progress callback

      // Return the file name
      return fileName;
    } else {
      throw new Error('Failed to generate signed upload URL');
    }
  } catch (err) {
    console.error('Error during file upload:', err);
    throw err;
  }
};
