import React from 'react';
import { Helmet } from 'react-helmet-async';

const TermsOfUse: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Terms of Use</title>
      </Helmet>
      <div className="container pt-5">
        <div className="col-12">
          <div className="row">
            <h2>Terms of Use for ArtHista</h2>
            <p>
              <strong>Effective Date: Nov 1, 2023</strong>
            </p>

            <h3 style={styles.h3}>1. Acceptance of Terms</h3>
            <p>
              By accessing and using the ArtHista ("App"), you agree to be bound by these Terms of Use ("Terms"). If you do not
              agree to these Terms, do not use the App.
            </p>

            <h3 style={styles.h3}>2. Changes to Terms</h3>
            <p>
              We reserve the right to modify these Terms at any time. We will post the most current version of these Terms on our
              website with the effective date. Your continued use of the App after any changes signifies your acceptance of our
              updated Terms.
            </p>

            <h3 style={styles.h3}>3. Use of the App</h3>
            <p>
              The App is intended for personal, non-commercial use. You may not use the App for illegal or unauthorized purposes.
              You are responsible for all your activity in connection with the App.
            </p>

            <h3 style={styles.h3}>4. Intellectual Property</h3>
            <p>
              All content provided on the App, including but not limited to text, graphics, images, and software, is the property
              of Software Innovation Lab LLC or its content suppliers and protected by international copyright and intellectual
              property laws.
            </p>

            <h3 style={styles.h3}>5. User Conduct</h3>
            <p>
              You agree not to use the App in a manner that is harmful, fraudulent, deceptive, threatening, harassing, defamatory,
              obscene, or otherwise objectionable.
            </p>

            <h3 style={styles.h3}>6. User-Generated Content</h3>
            <p>
              By uploading images or other content to the App, you represent and warrant that you own or have the necessary rights
              to upload such content and that it does not infringe on any third-party rights, including copyright, trademark, or
              privacy rights.
            </p>

            <h3 style={styles.h3}>7. Rights Granted by Users</h3>
            <p>
              By uploading content to the App, you grant Software Innovation Lab LLC a non-exclusive, perpetual, irrevocable,
              royalty-free, worldwide license to use, display, reproduce, modify, distribute, and create derivative works of the
              content for the purpose of operating the App and providing services to users.
            </p>

            <h3 style={styles.h3}>8. Prohibition on Uploading Copyrighted Material</h3>
            <p>
              You agree not to upload any content to the App that you do not have the legal right to use, including copyrighted
              material owned by third parties. You represent and warrant that you have obtained all necessary permissions and
              licenses to grant the rights described in these Terms.
            </p>

            <h3 style={styles.h3}>9. Disclaimer of Warranties</h3>
            <p>
              The App is provided "as is" without any warranties of any kind, either express or implied, including but not limited
              to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
            </p>

            <h3 style={styles.h3}>10. Limitation of Liability</h3>
            <p>
              Software Innovation Lab LLC shall not be liable for any indirect, incidental, special, consequential, or punitive
              damages arising out of or in connection with your use of the App.
            </p>

            <h3 style={styles.h3}>11. Governing Law</h3>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of Washington State, USA, without regard
              to its conflict of law provisions.
            </p>

            <h3 style={styles.h3}>12. Contact Information</h3>
            <p>
              For any questions or concerns regarding these Terms, please contact us at{' '}
              <a href="mailto:support@arthista.com">support@arthista.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;

const styles: { [key: string]: React.CSSProperties } = {
  h3: {
    fontSize: '1.4em',
    marginTop: '30px',
  },
};
