import React, { useState } from 'react';
import { Alert, Button, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // Add this import
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive'; // Add this import
import { Link } from 'react-router-dom';

import CompConfirmDialog from '../../components/CompConfirmDialog';
import { useDeleteMakerMutation, useGetAllMakersQuery } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';
import CreateMakerPage from './CreateMakerPage';

const MakerListPage: React.FC = () => {
  const [showCreateMakerModal, setShowCreateMakerModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [makerToDelete, setMakerToDelete] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Set the limit per page

  const lang = useSelector((state: RootState) => state.core.settings.lang);
  const auth = useSelector((state: RootState) => state.auth.auth);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Add this line
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useGetAllMakersQuery({
    variables: { page: currentPage, limit: itemsPerPage, lang: lang },
  });

  const [deleteMakerMutation] = useDeleteMakerMutation();

  const handleAddMaker = () => {
    setShowCreateMakerModal(true);
  };

  const handleCreateMakerModalClose = () => {
    setShowCreateMakerModal(false);
  };

  const handleCreateMakerModalSuccess = async () => {
    setShowCreateMakerModal(false);
    await refetch();
  };

  const handleDeleteMaker = (makerId: string) => {
    setMakerToDelete(makerId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteMaker = async () => {
    if (makerToDelete) {
      try {
        await deleteMakerMutation({ variables: { id: makerToDelete } });
        setShowDeleteConfirmModal(false);
        setMakerToDelete(null);
        await refetch();
      } catch (err) {
        console.error('Error deleting maker:', err);
        alert('Failed to delete maker. Please try again.');
      }
    }
  };

  const handleCancelDelete = () => {
    setMakerToDelete(null);
    setShowDeleteConfirmModal(false);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1); // Correctly set the page
  };

  const filteredMakers = data?.getAllMakers?.makers;

  const totalPages = Math.max(Math.ceil((data?.getAllMakers?.total || 0) / itemsPerPage), 1);

  const renderPagination = () => (
    <Row className="mt-4">
      <Col className="text-center">
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
          forcePage={currentPage - 1} // Ensure the current page is highlighted
        />
      </Col>
    </Row>
  );

  if (loading) {
    return (
      <Container className={isMobile ? 'mt-2' : 'mt-5'}>
        <Spinner animation="border" role="status" />
        <span className="sr-only">{t('loading')}</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={isMobile ? 'mt-2' : 'mt-5'}>
        <Alert variant="danger">'error': {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <div className="d-flex justify-content-between mb-4">
        <h2>{t('Artists')}:</h2>
        {auth.role === 'ADMIN' && (
          <Button variant="primary" onClick={handleAddMaker}>
            {t('addNew')}
          </Button>
        )}
      </div>
      {renderPagination()}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('birthPlace')}</th>
            <th>{t('years')}</th>
            {auth.role === 'ADMIN' && <th></th>}
          </tr>
        </thead>
        <tbody>
          {filteredMakers?.length ? (
            filteredMakers.map((maker) => (
              <tr key={maker.id}>
                <td>
                  <Link to={`/makers/${maker.id}`}>{getDefaultText(maker, 'name')}</Link>
                </td>
                <td>{maker.birthPlace}</td>
                <td>{`${maker.dateOfBirth || ''} - ${maker.dateOfDeath || ''}`}</td>
                {auth.role === 'ADMIN' && (
                  <td>
                    <Button variant="outline-danger" size="sm" onClick={() => handleDeleteMaker(maker.id)}>
                      {t('delete')}
                    </Button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={auth.role === 'ADMIN' ? 4 : 3} className="text-center">
                {t('noMakersFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {renderPagination()}
      {/* Modal for creating new maker */}
      <Modal show={showCreateMakerModal} onHide={handleCreateMakerModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('createNewMaker')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateMakerPage onClose={handleCreateMakerModalClose} onSuccess={handleCreateMakerModalSuccess} />
        </Modal.Body>
      </Modal>
      <CompConfirmDialog
        show={showDeleteConfirmModal}
        onHide={handleCancelDelete}
        onConfirm={confirmDeleteMaker}
        title={t('confirmDeletion')}
        body={t('confirmDeletionMessage')}
      />
    </Container>
  );
};

export default MakerListPage;
