import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy</title>
      </Helmet>
      <div className="container pt-5">
        <div className="col-12">
          <div className="row">
            <h2>Privacy Statement for ArtHista</h2>
            <p>
              <strong>Effective Date: Nov 1, 2024</strong>
            </p>

            <h3 style={styles.h3}>Introduction</h3>
            <p>
              Welcome to ArtHista. We are committed to protecting your privacy and ensuring a secure online experience. This
              Privacy Statement outlines our practices regarding the collection, use, and disclosure of information through our
              website.
            </p>

            <h3 style={styles.h3}>Information Collection</h3>
            <p>
              Our website allows users to upload pictures of artwork and get information about that artwork. We collect the
              following types of information:
            </p>

            <h4>Personal Information:</h4>
            <ul>
              <li>Email addresses for account creation and communication purposes.</li>
              <li>Uploaded images of artwork.</li>
            </ul>

            <h4>What We Don't Collect:</h4>
            <ul>
              <li>
                We do not collect physical addresses, phone numbers, or any other personal data that could be used to directly
                identify you.
              </li>
              <li>We do not track or store your location information.</li>
              <li>
                We do not collect sensitive information such as payment details, as our website is free to use and does not
                include in-app purchases.
              </li>
            </ul>

            <h4>Usage Data:</h4>
            <ul>
              <li>
                We collect anonymized usage data strictly for improving website functionality and user experience. This includes
                data like frequently accessed sections and general usage patterns.
              </li>
              <li>All usage data is fully anonymized and cannot be traced back to any individual user.</li>
            </ul>

            <h3 style={styles.h3}>Data Use and Sharing</h3>
            <p>
              The anonymized data we collect is used solely for the purpose of enhancing website performance, fixing bugs, and
              developing new features. We do not share, sell, rent, or trade any anonymized data with third parties for their
              commercial purposes.
            </p>

            <h3 style={styles.h3}>Data Security</h3>
            <p>
              We employ industry-standard security measures to protect the data we collect. Regular audits and updates are
              conducted to ensure the ongoing security and integrity of our systems.
            </p>

            <h3 style={styles.h3}>Children's Privacy</h3>
            <p>
              Our website is suitable for users of all ages. However, we do not knowingly collect or maintain data from children
              under the age of 13.
            </p>

            <h3 style={styles.h3}>Cookies and Tracking Technologies</h3>
            <p>
              We use cookies and similar tracking technologies to track the activity on our website and hold certain information.
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
            </p>

            <h3 style={styles.h3}>Third-Party Services</h3>
            <p>
              We may use third-party services for analytics and other purposes. These third parties may have access to your data
              to perform their tasks on our behalf and are obligated not to disclose or use it for any other purpose.
            </p>

            <h3 style={styles.h3}>Data Retention</h3>
            <p>
              We retain your personal data only for as long as is necessary for the purposes set out in this Privacy Statement. We
              will retain and use your data to the extent necessary to comply with our legal obligations, resolve disputes, and
              enforce our policies.
            </p>

            <h3 style={styles.h3}>User Rights</h3>
            <p>
              You have the right to access, correct, or delete your personal data. If you wish to exercise these rights, please
              contact us at the email address provided below.
            </p>

            <h3 style={styles.h3}>Compliance with Laws</h3>
            <p>
              We comply with applicable data protection laws, including the General Data Protection Regulation (GDPR) and the
              California Consumer Privacy Act (CCPA).
            </p>

            <h3 style={styles.h3}>Changes to this Privacy Statement</h3>
            <p>
              We may update this Privacy Statement periodically. The effective date at the top of the Privacy Statement will be
              updated accordingly. We encourage users to review this statement regularly to stay informed about our privacy
              practices.
            </p>

            <h3 style={styles.h3}>Contact Information</h3>
            <p>
              If you have any questions or concerns about this Privacy Statement, please contact us at
              <a href="mailto:support@arthista.com">support@arthista.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;

const styles: { [key: string]: React.CSSProperties } = {
  h3: {
    fontSize: '1.4em',
    marginTop: '30px',
  },
};
