import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface CompConfirmDialogProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  title: string;
  body: string;
}

const CompConfirmDialog: React.FC<CompConfirmDialogProps> = ({ show, onHide, onConfirm, title, body }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={onHide}>
          {t('cancel')}
        </Button>
        <Button variant="outline-danger" onClick={onConfirm}>
          {t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompConfirmDialog;

const styles: { [key: string]: React.CSSProperties } = {};
