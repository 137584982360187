import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import CompConfirmDialog from '../../components/CompConfirmDialog';
import { useDeleteLocationMutation, useGetAllLocationsQuery } from '../../graphql/server-graphql-schema';
import CreateLocationPage from './CreateLocationPage';

const LocationListPage: React.FC = () => {
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Set the limit per page
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading, error, refetch } = useGetAllLocationsQuery({
    variables: { page, limit },
  });

  const [deleteLocationMutation] = useDeleteLocationMutation();
  const { t } = useTranslation();

  const handleAddLocation = () => {
    setShowCreateLocationModal(true);
  };

  const handleCreateLocationModalClose = () => {
    setShowCreateLocationModal(false);
  };

  const handleCreateLocationModalSuccess = async () => {
    setShowCreateLocationModal(false);
    await refetch();
  };

  const handleDeleteLocation = (locationId: string) => {
    setLocationToDelete(locationId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteLocation = async () => {
    if (locationToDelete) {
      try {
        await deleteLocationMutation({ variables: { id: locationToDelete } });
        setShowDeleteConfirmModal(false);
        setLocationToDelete(null);
        await refetch();
      } catch (err) {
        console.error('Error deleting location:', err);
        alert('Failed to delete location. Please try again.');
      }
    }
  };

  const handleCancelDelete = () => {
    setLocationToDelete(null);
    setShowDeleteConfirmModal(false);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const filteredLocations = data?.getAllLocations?.locations.filter((location) =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const totalPages = Math.max(Math.ceil((data?.getAllLocations?.total || 0) / limit), 1);

  const renderPagination = () => (
    <Row className="mt-4">
      <Col className="text-center">
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({ selected }) => handlePageChange(selected + 1)}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
          forcePage={totalPages > 0 ? page - 1 : 0} // Ensure the current page is highlighted
        />
      </Col>
    </Row>
  );

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">{t('loading')}</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          {t('error')}: {error.message}
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <div className="d-flex justify-content-between mb-4">
        <h2>{t('locationList')}</h2>
        <Button variant="primary" onClick={handleAddLocation}>
          {t('addNew')}
        </Button>
      </div>

      <Form className="mb-3">
        <Form.Control
          type="text"
          placeholder={t('searchLocations')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Form>

      {renderPagination()}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('city')}</th>
            <th>{t('country')}</th>
            <th>{t('type')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredLocations?.length ? (
            filteredLocations.map((location) => (
              <tr key={location.id}>
                <td>{location.name}</td>
                <td>{location.city}</td>
                <td>{location.country}</td>
                <td>{location.type}</td>
                <td>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDeleteLocation(location.id)}>
                    {t('delete')}
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                {t('noLocationsFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {renderPagination()}

      {/* Modal for creating new location */}
      <Modal show={showCreateLocationModal} onHide={handleCreateLocationModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('createNewLocation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateLocationPage onClose={handleCreateLocationModalClose} onSuccess={handleCreateLocationModalSuccess} />
        </Modal.Body>
      </Modal>

      <CompConfirmDialog
        show={showDeleteConfirmModal}
        onHide={handleCancelDelete}
        onConfirm={confirmDeleteLocation}
        title={t('confirmDeletion')}
        body={t('confirmDeletionMessage')}
      />
    </Container>
  );
};

export default LocationListPage;
