import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useGetAllArtworksAndMakersQuery } from '../../graphql/server-graphql-schema';
import { setArtworks, setMakers } from '../../store/coreSlice';

const CompLoadArtworkAndMakers: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data,
    loading: queryLoading,
    error,
  } = useGetAllArtworksAndMakersQuery({
    variables: {
      page: 1,
      limit: 10000,
      lang: 'en',
    },
    skip: false,
  });

  useEffect(() => {
    if (data) {
      dispatch(setArtworks(data.getAllArtworks.artworks));
      dispatch(setMakers(data.getAllMakers.makers));
    }
    if (error) {
      console.error(error);
    }
  }, [data, error, dispatch]);

  if (queryLoading) {
    return (
      <div>
        <Spinner animation="border" size="sm" className="ms-3" /> Loading ...
      </div>
    );
  }

  return null;
};

export default CompLoadArtworkAndMakers;
