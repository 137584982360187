import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon you need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clearAuth } from '../store/authSlice';

const LogoutButton: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(clearAuth());
  };

  return (
    <Button variant="outline-primary" onClick={handleLogout}>
      <FontAwesomeIcon icon={faRightFromBracket} className="me-2" /> {t('logoutButton')}
    </Button>
  );
};

export default LogoutButton;
