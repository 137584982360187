import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CompLocationSelector from '../../../components/CompLocationSelector';
import CompRequireLoginAlert from '../../../components/CompRequireLoginAlert';
import { resetUpload, setError, setStep } from '../../../store/coreSlice';
import { RootState } from '../../../store/store';
import { formHasData } from '../../../utility/renderHelpers';
import CompAddArtworkImageHeader from './CompAddArtworkImageHeader';
import CompDragDropImageUpload from './CompDragDropImageUpload';
import CompDragDropInfocardUpload from './CompDragDropInfocardUpload';
import CompOcrResultForm from './CompOcrResultForm';
import CompUploadOcrArtwork from './CompUploadOcrArtwork';

const CustomCircle: React.FC<{ number: number; text: string; color: string; onClick: () => void }> = ({
  number,
  color,
  text,
  onClick,
}) => (
  <svg width="110" height="36" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: 'pointer' }}>
    <circle cx="14" cy="18" r="14" fill={color} />
    <text x="13" y="22.8" fontFamily="Arial, sans-serif" fontSize="16" textAnchor="middle" fill="white">
      {number}
    </text>
    <text x="33" y="22.8" fontFamily="Arial, sans-serif" fontSize="16" fill="black">
      {text}
    </text>
  </svg>
);

const CreateArtworkSimplePage: React.FC = () => {
  const { t } = useTranslation();
  const fromMinimal = useSelector((state: RootState) => state.core.fromMinimal);
  const error = useSelector((state: RootState) => state.core.error);
  const step = useSelector((state: RootState) => state.core.step);
  const ocrArtwork = useSelector((state: RootState) => state.core.ocrArtwork);
  const artwork = useSelector((state: RootState) => state.core.artwork);

  const dispatch = useDispatch();

  const nextStep = () => dispatch(setStep(step + 1));
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const resetState = () => {
    dispatch(setError(null));
    dispatch(resetUpload());
  };

  const [isOcrFormVisible, setIsOcrFormVisible] = useState(false);

  useEffect(() => {
    if (fromMinimal) {
      setIsOcrFormVisible(true);
    }
  }, [fromMinimal]);

  useEffect(() => {
    if (step === 1) {
      resetState();
    }
  }, [step]);

  const toggleOcrFormVisibility = () => {
    setIsOcrFormVisible(!isOcrFormVisible);
  };

  const goToStep = (step: number) => {
    if (!formHasData(ocrArtwork)) {
      resetState();
      dispatch(setStep(1));
      return;
    }

    if (step === 1 && fromMinimal) {
      resetState();
    }

    dispatch(setStep(step));
  };

  return (
    <Container>
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item href="/artworks">{t('breadcrumbs.artworks')}</Breadcrumb.Item>
        <Breadcrumb.Item active>{t('breadcrumbs.addArtwork')}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          position: 'relative',
          width: '100%',
          marginBottom: isMobile ? '20px' : '10px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        }}
      >
        <CompAddArtworkImageHeader />
      </div>
      <CompRequireLoginAlert />
      <div style={styles.progressContainer}>
        <div className="mb-4" style={styles.progressSteps}>
          <div style={step === 1 ? styles.activeStep : {}}>
            <CustomCircle
              number={1}
              text={t('steps.label', { context: 'abbreviation' })}
              color={step >= 1 ? '#3498db' : 'gray'}
              onClick={() => goToStep(1)}
            />
          </div>
          <div style={step === 2 ? styles.activeStep : {}}>
            <CustomCircle
              number={2}
              text={t('steps.picture')}
              color={step >= 2 ? '#3498db' : 'gray'}
              onClick={() => goToStep(2)}
            />
          </div>
          <div style={step === 3 ? styles.activeStep : {}}>
            <CustomCircle number={3} text={t('Search')} color={step >= 3 ? '#3498db' : 'gray'} onClick={() => goToStep(3)} />
          </div>
        </div>

        <div className="mb-2" style={styles.buttonRow}>
          {step < 3 && formHasData(ocrArtwork) && (
            <Button variant="outline-primary" onClick={nextStep}>
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          )}
          {step === 3 && <CompUploadOcrArtwork />}
        </div>
      </div>

      <div className="mb-5 text-center text-danger">
        {error}
        {fromMinimal && !formHasData(ocrArtwork) && <span>{t('errorMessages.artworkNameOrArtistRequired')}</span>}
      </div>
      <div style={styles.progressContainer}>
        <div style={{ display: step === 1 && !fromMinimal ? 'block' : 'none', width: '100%' }}>
          <CompDragDropInfocardUpload />
        </div>
        <div style={{ display: step === 2 ? 'block' : 'none', width: '100%' }}>
          <CompDragDropImageUpload />
        </div>
        <div style={{ display: (step === 3 && !fromMinimal) || (step === 1 && fromMinimal) ? 'block' : 'none', width: '100%' }}>
          {!artwork && (
            <>
              <div className="mb-3">
                <h4 className="my-3">{t('locationPrompt')}</h4>
                <CompLocationSelector />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '20px', marginTop: '30px' }}>
                <Button variant="outline-secondary" onClick={toggleOcrFormVisibility}>
                  <FontAwesomeIcon icon={isOcrFormVisible ? faChevronUp : faChevronDown} />
                </Button>
              </div>
              {isOcrFormVisible && <CompOcrResultForm />}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CreateArtworkSimplePage;

const styles: { [key: string]: React.CSSProperties } = {
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  progressSteps: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: '600px',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '20px',
  },
  activeStep: {
    borderBottom: '2px solid #3498db',
    paddingBottom: '5px',
  },
};
