import { SUPPORTED_LANGS } from '../constants';

export const getDefaultText = (item: any, field: string) => {
  if (!item) {
    return null;
  }

  return item.text.lang?.[field]?.default?.text || item.text.en?.[field]?.default?.text;
};

export const getFlagIcon = (langCode: string) => {
  const lang = SUPPORTED_LANGS.find((l) => l.code === langCode);
  return lang ? lang.flag : '';
};
