import './App.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { SpeedInsights } from '@vercel/speed-insights/react';

import App from './App';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if ((module as any).hot) {
  (module as any).hot.accept(() => {
    console.clear();
    console.log('>>>reloaded');
  });
}

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
      <SpeedInsights />
    </I18nextProvider>
  </React.StrictMode>,
);
