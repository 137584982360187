import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SUPPORTED_LANGS } from '../constants';
import i18n from '../i18n';
import { updateSettings } from '../store/coreSlice';
import { RootState } from '../store/store';

const CompLanguageDropdownComponent: React.FC = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state: RootState) => state.core.settings.lang);
  const { t } = useTranslation();
  const [flagIcon, setFlagIcon] = useState(SUPPORTED_LANGS.find((lang) => lang.code === currentLang)?.flag || 'flag-icon-us');

  useEffect(() => {
    setFlagIcon(SUPPORTED_LANGS.find((lang) => lang.code === currentLang)?.flag || 'flag-icon-us');
  }, [currentLang]);

  const handleLanguageChange = (lang: string) => {
    void i18n.changeLanguage(lang); // Change the i18n language
    dispatch(updateSettings({ lang })); // Update the language setting in the Redux store
  };

  return (
    <NavDropdown
      title={
        <>
          <span className={`flag-icon ${flagIcon}`}></span>
        </>
      }
      id="collasible-nav-dropdown"
    >
      {SUPPORTED_LANGS.map((lang) => (
        <NavDropdown.Item key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
          <span className={`flag-icon ${lang.flag} me-2`}></span> {t(lang.label)}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default CompLanguageDropdownComponent;
