import React, { useEffect } from 'react';
import { Alert, Breadcrumb, Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CompImageThumbs from '../../components/CompImageThumbs';
import CompDownloadAndPlayVoice from '../../components/DownloadAndPlayVoice';
import { useGetArtworkByIdQuery } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';

interface MetaInfo {
  label: string;
  value: string | undefined;
}

const ArtworkDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { artworkId } = useParams<{ artworkId: string }>();

  const auth = useSelector((state: RootState) => state.auth.auth);
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { data, loading, error, refetch } = useGetArtworkByIdQuery({
    variables: { id: artworkId || '', lang },
    skip: !artworkId,
    fetchPolicy: 'cache-and-network',
  });

  const formatMetaInfo = (meta: any): MetaInfo[] => {
    return [
      { label: t('material'), value: meta?.material },
      { label: t('movement'), value: meta?.movement },
      { label: t('currentLocation'), value: meta?.currentLocation },
      { label: t('medium'), value: meta?.medium },
      { label: t('technique'), value: meta?.technique },
    ].filter((item) => item.value);
  };
  useEffect(() => {
    if (artworkId) {
      void refetch();
    }
  }, [artworkId, refetch]);

  const handleBreadcrumbClick = (path: string) => {
    void navigate(path);
  };

  const isMyArtworks = location.pathname.includes('/my-artworks');

  if (loading) {
    return (
      <Container className="mt-5">
        <p>{t('loading')}</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          {t('errorLoadingArtwork')}: {error.message}
        </Alert>
      </Container>
    );
  }

  const artwork = data?.getArtworkById;

  if (!artwork) {
    return (
      <Container className="mt-5">
        <Alert variant="warning">{t('artworkNotFound')}</Alert>
      </Container>
    );
  }

  const nameText = getDefaultText(artwork, 'name') || t('untitled');
  const descriptionText = getDefaultText(artwork, 'description');
  const makerName = artwork.makerName || getDefaultText(artwork.maker, 'name') || t('unknown');

  const metaInfo = formatMetaInfo(artwork.meta);

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <div className="d-flex justify-content-between align-items-center">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => handleBreadcrumbClick(isMyArtworks ? `/my-artworks/${auth.id}` : '/artworks')}>
            {isMyArtworks ? t('myArtworks') : t('artworks')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('artworkDetail')}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row>
        <Col>
          <h2 className="mt-3 mb-4">{nameText}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          {makerName && (
            <div className="d-flex justify-content-between">
              <strong>{t('by')}: </strong> {makerName}
            </div>
          )}
          {artwork.dateStarted && (
            <div className="d-flex justify-content-between">
              <strong>{t('dateStarted')}: </strong> {artwork.dateStarted}
            </div>
          )}
          {artwork.locationName && (
            <div className="d-flex justify-content-between">
              <strong>{t('createdIn')}: </strong> {artwork.locationName}
            </div>
          )}
          {artwork.archiveId && (
            <div className="d-flex justify-content-between">
              <strong>{t('archiveId')}: </strong> {artwork.archiveId}
            </div>
          )}
          {metaInfo.map((info, index) => (
            <div key={index} className="d-flex justify-content-between">
              <strong>{t(info.label)}: </strong> {info.value}
            </div>
          ))}
        </Col>

        <Col md={6} className="d-flex flex-column">
          {descriptionText && (
            <div className="mb-3" style={{ textAlign: 'justify' }}>
              {descriptionText}
            </div>
          )}
          <CompDownloadAndPlayVoice item={artwork} lang={lang} onUpdate={() => void refetch()} />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <div style={styles.mediaContainer}>
            <CompImageThumbs artwork={artwork} showDate={true} />
          </div>
        </Col>
      </Row>

      {auth.role === 'ADMIN' && (
        <Button variant="outline-primary" onClick={() => navigate(`/edit-artwork/${artworkId}`)}>
          {t('editArtwork')}
        </Button>
      )}
    </Container>
  );
};

export default ArtworkDetailsPage;

const styles: { [key: string]: React.CSSProperties } = {
  mediaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  mediaItem: {
    flex: '0 1 150px',
    textAlign: 'center',
  },
  mediaImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
};
