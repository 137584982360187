import React, { useEffect } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { useUpsertMakerMutation } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';

type CreateMakerPageProps = {
  onClose: () => void;
  onSuccess: (newMakerId: string, name: string) => void;
  initialName?: string;
};

// Define Zod validation schema
const validationSchema = z.object({
  dateOfBirth: z.string().nullable(),
  dateOfDeath: z.string().nullable(),
  birthPlace: z.string().nonempty('Birth Place is required'),
  meta: z.string().refine((value) => {
    try {
      if (value) {
        JSON.parse(value);
      }
      return true;
    } catch {
      return false;
    }
  }, 'Meta must be valid JSON'),
  name: z.string().nonempty('Name is required'),
  description: z.string().nonempty('Description is required'),
});

const CreateMakerPage: React.FC<CreateMakerPageProps> = ({ onClose, onSuccess, initialName = '' }) => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onChange', // Validate on each field change
    defaultValues: {
      dateOfBirth: '',
      dateOfDeath: '',
      birthPlace: '',
      meta: '',
      name: initialName,
      description: '',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = methods;

  const [upsertMakerMutation] = useUpsertMakerMutation();
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  useEffect(() => {
    // Trigger validation for all fields after the form is loaded
    void trigger();
  }, [trigger]);

  const handleSaveMaker = async (data: any) => {
    try {
      const parsedMeta = data.meta ? JSON.parse(data.meta) : null;

      const response = await upsertMakerMutation({
        variables: {
          input: {
            lang: lang,
            dateOfBirth: data.dateOfBirth || undefined,
            dateOfDeath: data.dateOfDeath || undefined,
            birthPlace: data.birthPlace,
            name: data.name,
            description: data.description,
            meta: parsedMeta,
          },
        },
      });

      if (response.data?.upsertMaker?.id) {
        onSuccess(response.data.upsertMaker.id, data.name);
      } else {
        throw new Error('Failed to retrieve Maker ID after save');
      }

      onClose();
    } catch (error: any) {
      console.error('Error upserting maker:', error);
    }
  };

  return (
    <FormProvider {...methods}>
      <Container className="mt-3">
        <Card className="mb-4 p-3">
          <Card.Title>{t('basicInformation')}</Card.Title>

          <Form onSubmit={handleSubmit(handleSaveMaker)}>
            <Form.Group controlId="formDateOfBirth" className="mt-2">
              <Form.Label>{t('dateOfBirth')}</Form.Label>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="YYYY-MM-DD"
                    {...field}
                    value={field.value || ''} // Ensure the value is always a string
                    isInvalid={!!errors.dateOfBirth}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.dateOfBirth?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formDateOfDeath" className="mt-2">
              <Form.Label>{t('dateOfDeath')}</Form.Label>
              <Controller
                name="dateOfDeath"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="YYYY-MM-DD"
                    {...field}
                    value={field.value || ''} // Ensure the value is always a string
                    isInvalid={!!errors.dateOfDeath}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.dateOfDeath?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBirthPlace" className="mt-2">
              <Form.Label>{t('birthPlace')}</Form.Label>
              <Controller
                name="birthPlace"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    {...field}
                    value={field.value || ''} // Ensure the value is always a string
                    isInvalid={!!errors.birthPlace}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.birthPlace?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formMeta" className="mt-2">
              <Form.Label>{t('meta')} (JSON)</Form.Label>
              <Controller
                name="meta"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    as="textarea"
                    placeholder='{"key": "value"}'
                    {...field}
                    value={field.value || ''} // Ensure the value is always a string
                    isInvalid={!!errors.meta}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.meta?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formName" className="mt-2">
              <Form.Label>{t('name')}</Form.Label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    {...field}
                    value={field.value || ''} // Ensure the value is always a string
                    isInvalid={!!errors.name}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formDescription" className="mt-2">
              <Form.Label>{t('description')}</Form.Label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    as="textarea"
                    {...field}
                    value={field.value || ''} // Ensure the value is always a string
                    isInvalid={!!errors.description}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Button variant="secondary" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button variant="primary" type="submit">
                {t('saveMaker')}
              </Button>
            </div>
          </Form>
        </Card>
      </Container>
    </FormProvider>
  );
};

export default CreateMakerPage;
