import { PayloadAction } from '@reduxjs/toolkit';

import { OcrArtwork } from '../graphql/server-graphql-schema';
import { CoreState } from './coreSlice';

export const ACTION_ARTWORK = {
  updateOcrArtwork: (state: CoreState, action: PayloadAction<Partial<OcrArtwork>>) => {
    if (state.ocrArtwork) {
      state.ocrArtwork = {
        ...state.ocrArtwork,
        ...action.payload,
      };
    } else {
      state.ocrArtwork = action.payload;
    }
  },

  setArtworkId: (state: CoreState, action: PayloadAction<string>) => {
    state.artworkId = action.payload;
  },
};
