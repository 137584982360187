export const PRACTICE_SCHEMA = 2;
export const SENTENCE_SCHEMA = 1;
export const MINIMUM_PRACTICE_SCHEMA = 2;

export const CONTENT_BASE_URL = 'https://storage.googleapis.com/static.arthista.com/';

export enum AppSettingsType {
  PROMPTS = 'PROMPTS',
  CLIENT = 'CLIENT',
}

export const MAX_IMAGE_SIZE_MB = 10 * 1024 * 1024; // images above this size are resized
export const BBOX_PADDING = 100; // Define the padding for the bounding box
export const MAX_INFOCARD_IMAGE_DIMENSION = 800;

export const SUPPORTED_LANGS = [
  { code: 'en', label: 'languages.en', flag: 'flag-icon-us' },
  { code: 'ja', label: 'languages.ja', flag: 'flag-icon-jp' },
  { code: 'de', label: 'languages.de', flag: 'flag-icon-de' },
  { code: 'es-ES', label: 'languages.es-ES', flag: 'flag-icon-es' },
  { code: 'ko', label: 'languages.ko', flag: 'flag-icon-kr' },
  { code: 'fr', label: 'languages.fr', flag: 'flag-icon-fr' },
  { code: 'ru', label: 'languages.ru', flag: 'flag-icon-ru' },
  { code: 'it', label: 'languages.it', flag: 'flag-icon-it' },
  { code: 'zh-CN', label: 'languages.zh-CN', flag: 'flag-icon-cn' },
  { code: 'zh-TW', label: 'languages.zh-TW', flag: 'flag-icon-tw' },
];
