import React, { useEffect } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OcrLocalizedText } from '../../../graphql/server-graphql-schema';
import { updateOcrArtwork } from '../../../store/coreSlice';
import { RootState } from '../../../store/store';

type CompOcrResultFormProps = {};

const CompOcrResultForm: React.FC<CompOcrResultFormProps> = ({}) => {
  const { t } = useTranslation();
  const error = useSelector((state: RootState) => state.core.error);
  const ocrArtwork = useSelector((state: RootState) => state.core.ocrArtwork);
  const fromMinimal = useSelector((state: RootState) => state.core.fromMinimal);
  const lang = useSelector((state: RootState) => state.core.settings.lang);

  useEffect(() => {
    if (fromMinimal) {
      dispatch(
        updateOcrArtwork({
          artistNames: [{ lang: lang, text: '' }],
          artworkNames: [{ lang: lang, text: '' }],
          dateOfCreation: '',
        }),
      );
    }
  }, [fromMinimal, lang]);

  const dispatch = useDispatch();

  const handleFieldChange = (field: string, value: string) => {
    if (ocrArtwork) {
      dispatch(updateOcrArtwork({ [field]: value }));
    }
  };

  const handleLocalizedFieldChange = (field: string, value: string, lang: string) => {
    if (ocrArtwork) {
      const [mainField, index, subField] = field.split('.');
      dispatch(
        updateOcrArtwork({
          [mainField]: (ocrArtwork[mainField as keyof typeof ocrArtwork] as OcrLocalizedText[]).map(
            (item: OcrLocalizedText, i: number) => (i === parseInt(index) ? { ...item, [subField]: value, lang } : item),
          ),
        }),
      );
    }
  };

  const fieldLabelMapping: { [key: string]: string } = {
    archiveId: t('fields.archiveId'),
    dateOfCreation: t('fields.dateOfCreation'),
    material: t('fields.material'),
    dateOfBirthOfArtist: t('fields.dateOfBirthOfArtist'),
    dateOfDeathOfArtist: t('fields.dateOfDeathOfArtist'),
    locationOfBirthOfArtist: t('fields.locationOfBirthOfArtist'),
    locationOfDeathOfArtist: t('fields.locationOfDeathOfArtist'),
    artworkLocation: t('fields.artworkLocation'),
  };

  const renderInputField = (
    field: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    clearField: () => void,
  ) => (
    <Form.Group className="mb-3">
      <Form.Label>{fieldLabelMapping[field] || field}</Form.Label>
      <InputGroup>
        <Form.Control type="text" value={value} onChange={onChange} />
        <InputGroup.Text onClick={clearField} style={styles.clearButton}>
          <FontAwesomeIcon icon={faTimes} />
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );

  return (
    <div className="mt-5">
      {ocrArtwork && (
        <Row>
          <Col md={6}>
            <Form>
              {ocrArtwork.artworkNames?.map((nameObj: OcrLocalizedText, index: number) => (
                <React.Fragment key={`artworkName-${index}`}>
                  {renderInputField(
                    `${t('fields.artworkName')} (${t('languages.' + nameObj.lang)})`,
                    nameObj.text || '',
                    (e) => handleLocalizedFieldChange(`artworkNames.${index}.text`, e.target.value, nameObj.lang),
                    () => handleLocalizedFieldChange(`artworkNames.${index}.text`, '', nameObj.lang),
                  )}
                </React.Fragment>
              ))}
              {ocrArtwork.artistNames?.map((artistObj: OcrLocalizedText, index: number) => (
                <React.Fragment key={`artistName-${index}`}>
                  {renderInputField(
                    `${t('fields.artistName')} (${t('languages.' + artistObj.lang)})`,
                    artistObj.text || '',
                    (e) => handleLocalizedFieldChange(`artistNames.${index}.text`, e.target.value, artistObj.lang),
                    () => handleLocalizedFieldChange(`artistNames.${index}.text`, '', artistObj.lang),
                  )}
                </React.Fragment>
              ))}
              {ocrArtwork.description?.map((descObj: OcrLocalizedText, index: number) => (
                <React.Fragment key={`description-${index}`}>
                  {renderInputField(
                    `${t('fields.description')} (${t('languages.' + descObj.lang)})`,
                    descObj.text || '',
                    (e) => handleLocalizedFieldChange(`description.${index}.text`, e.target.value, descObj.lang),
                    () => handleLocalizedFieldChange(`description.${index}.text`, '', descObj.lang),
                  )}
                </React.Fragment>
              ))}
            </Form>

            {error && <div style={styles.errorText}>{error}</div>}
          </Col>
          <Col md={6}>
            <Form>
              {[
                'archiveId',
                'dateOfCreation',
                'material',
                'dateOfBirthOfArtist',
                'dateOfDeathOfArtist',
                'locationOfBirthOfArtist',
                'locationOfDeathOfArtist',
                'artworkLocation',
              ].map((key, index) => {
                const value = ocrArtwork[key as keyof typeof ocrArtwork];
                if (typeof value === 'string') {
                  return (
                    <React.Fragment key={`infoField-${index}`}>
                      {renderInputField(
                        key,
                        value,
                        (e) => handleFieldChange(key, e.target.value),
                        () => handleFieldChange(key, ''),
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CompOcrResultForm;

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  uploadArea: {
    position: 'absolute',
    bottom: 0,
    height: '33%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    border: '2px dashed #cccccc',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
  clearButton: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: '24px',
  },
  uploadButtonContainer: {
    textAlign: 'center',
    marginTop: '50px',
  },
};
