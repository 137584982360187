import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FooterComponent: React.FC = () => {
  return (
    <footer className="bg-light mt-auto">
      <Container className="d-flex justify-content-center">
        <Nav>
          <Nav.Link as={Link} to="/privacy">
            Privacy
          </Nav.Link>
          <Nav.Link as={Link} to="/tou" className="ms-3">
            Terms of Service
          </Nav.Link>
        </Nav>
      </Container>
    </footer>
  );
};

export default FooterComponent;
