import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import GoogleOAuth from '../components/GoogleOAuth';
import LogoutButton from '../components/LogoutButton';
import { upgradeStore } from '../store/coreSlice';
import { RootState } from '../store/store';

const LoginPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const redirectTo = location.state?.from?.pathname || '/';

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.auth);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    dispatch(upgradeStore());
  }, [auth]);

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <Helmet>
        <title>{t('loginTitle')}</title> {/* Localize this */}
      </Helmet>
      <div className="header-image-container" style={styles.headerImageContainer}>
        <img
          src={`${process.env.PUBLIC_URL}/images/museum4.png`}
          alt={t('loginHeaderImageAlt')}
          className="header-image"
          style={styles.headerImage}
        />
      </div>
      <h2 className="my-4 text-center">
        {!auth.email ? t('loginPrompt') : t('welcomeBack')} {/* Localize this */}
      </h2>
      {!auth.email ? (
        <>
          <p className="mt-5 text-center mx-auto" style={{ maxWidth: '70%' }}>
            {t('loginDescription')} {/* Localize this */}
            <span className="ms-2">
              <strong>{t('freeMention')}</strong> {/* Localize this */}
            </span>
          </p>
          <div className="mt-3 text-center">
            <GoogleOAuth redirectTo={redirectTo} />
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 text-center">
            <h3 style={styles.h3}>
              <span className="me-1">{t('hello')}</span> {/* Localize this */}
              {auth.email}
            </h3>
          </div>
          <p className="mt-3 text-center">{t('startExploring')}</p> {/* Localize this */}
          <div className="mt-5 text-center">
            <LogoutButton />
          </div>
        </>
      )}
    </Container>
  );
};

export default LoginPage;

const styles: { [key: string]: React.CSSProperties } = {
  headerImageContainer: {
    textAlign: 'center',
    width: '100%',
  },
  headerImage: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'contain', // Maintain aspect ratio
  },
};
