import React from 'react';
import { Alert, Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import CompDownloadAndPlayVoice from '../../components/DownloadAndPlayVoice';
import { useGetMakerByIdQuery } from '../../graphql/server-graphql-schema';
import { RootState } from '../../store/store';
import { getDefaultText } from '../../utility/text-util';

const MakerDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { makerId } = useParams<{ makerId: string }>();

  const lang = useSelector((state: RootState) => state.core.settings.lang);

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Add this line

  const { data, loading, error, refetch } = useGetMakerByIdQuery({
    variables: { id: makerId || '', lang },
    skip: !makerId,
    fetchPolicy: 'cache-and-network',
  });

  const handleBreadcrumbClick = (path: string) => {
    void navigate(path);
  };

  if (loading) {
    return (
      <Container className="mt-5">
        <p>{t('loading')}</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          {t('errorLoadingMaker')}: {error.message}
        </Alert>
      </Container>
    );
  }

  const maker = data?.getMakerById;

  if (!maker) {
    return (
      <Container className="mt-5">
        <Alert variant="warning">{t('makerNotFound')}</Alert>
      </Container>
    );
  }

  const nameText = getDefaultText(maker, 'name');
  const birthPlace = maker.birthPlace;
  const dateOfBirth = maker.dateOfBirth;
  const dateOfDeath = maker.dateOfDeath;
  const description = getDefaultText(maker, 'description');

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => handleBreadcrumbClick('/makers')}>{t('makers')}</Breadcrumb.Item>
        <Breadcrumb.Item active>{t('makerDetail')}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <h2 className="mt-3 mb-4">{nameText}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <div className="d-flex justify-content-between">
            <strong>{t('birthPlace')}:</strong> {birthPlace}
          </div>
          <div className="d-flex justify-content-between">
            <strong>{t('dateOfBirth')}:</strong> {dateOfBirth}
          </div>
          <div className="d-flex justify-content-between">
            <strong>{t('dateOfDeath')}:</strong> {dateOfDeath}
          </div>
        </Col>
        <Col md={6} className="d-flex flex-column mb-4">
          {description && (
            <div className="mb-3" style={{ textAlign: 'justify' }}>
              {description}
            </div>
          )}
          <CompDownloadAndPlayVoice item={maker} lang={lang} onUpdate={() => void refetch()} />
        </Col>
      </Row>
    </Container>
  );
};

export default MakerDetailsPage;
