import { Route, Routes } from 'react-router-dom';

import TranslatePage from './pages/admin/TranslatePage';
import AdminSettingsManagementPage from './pages/AdminSettings/AdminSettingsManagementPage';
import ArtworkDetailsPage from './pages/artwork/ArtworkDetailsPage';
import ArtworkListPage from './pages/artwork/ArtworkListPage';
import EditArtworkPage from './pages/artwork/EditArtworkPage';
import HomePage from './pages/home/HomePage';
import LocationListPage from './pages/location/LocationListPage';
import LoginPage from './pages/LoginPage';
import MakerDetailsPage from './pages/maker/MakerDetailsPage';
import MakerListPage from './pages/maker/MakerListPage';
import PrivacyPage from './pages/PrivacyPage';
import CreateArtworkSimplePage from './pages/Search/ImageSearch/CreateArtworkSimplePage';
import SearchPage from './pages/Search/SearchPage';
import SettingsPage from './pages/SettingsPage';
import TOUPage from './pages/TOUPage';
import UserListPage from './pages/user/UserListPage';

const PublicRoutes = () => (
  <Routes>
    <Route path="/appsettings" element={<AdminSettingsManagementPage />} />
    <Route path="/settings" element={<SettingsPage />} />
    <Route path="/artworks" element={<ArtworkListPage />} />
    <Route path="/my-artworks/:userId" element={<ArtworkListPage />} />
    <Route path="/artworks/location/:locationId" element={<ArtworkListPage />} /> {/* New route */}
    <Route path="/artworks/:artworkId" element={<ArtworkDetailsPage />} />
    <Route path="/my-artworks/:userId/:artworkId" element={<ArtworkDetailsPage />} />
    <Route path="/edit-artwork/:id" element={<EditArtworkPage />} />
    <Route path="/makers" element={<MakerListPage />} />
    <Route path="/makers/:makerId" element={<MakerDetailsPage />} />
    <Route path="/locations" element={<LocationListPage />} />
    <Route path="/users" element={<UserListPage />} />
    <Route path="/search-image" element={<CreateArtworkSimplePage />} />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/tou" element={<TOUPage />} />
    <Route path="/translate" element={<TranslatePage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="" element={<HomePage />} />
    <Route path="*" element={<p>Path not resolved</p>} />
  </Routes>
);

export default PublicRoutes;
