import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { RootState } from '../store/store';

const SettingsPage = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Container className={isMobile ? 'mt-2' : 'mt-5'}>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      {auth.email && (
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="my-5 d-flex justify-content-between align-items-center">
              <div>
                Logged in as:<br></br>
                {auth.email}
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SettingsPage;
